import { Navbar, Nav, Badge, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./NavBar.scss";
import { t } from "i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import SendFavsAlert from "../SendFavsAlert/SendFavsAlert";
import JSZip from "jszip";
import * as fileSaver from 'file-saver';
import { getBase64 } from "../../Services/DownloadService";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { logoutGallery } from "../../redux/actions/galleryActions";
import { logoutUser } from "../../redux/actions/userActions";
import { toggleFavsOff, toggleFavsOn } from "../../redux/actions/utilsActions";
import ConfirmationToast from "../Toasts/ConfirmationToast/ConfirmationToast";
import { APP_DOMAIN } from "../../config/config";


const NavBarComponent = () => {
  const navigate = useNavigate();
  const gallery = useSelector((state: any) => state.gallery?.gallery);
  const favsStatus = useSelector((state: any) => state.utils?.favsStatus);
  const gFullyLoaded = useSelector((state: any) => state.gallery?.fullyLoaded);
  const user = useSelector((state: any) => state.user?.user);
  const reduxDispatch = useDispatch();
  const [errorText, setErrorText] = useState('errors.MAX_FAVS_REACHED');

  /**
   * Log out of the current gallery and reset the global state
   */
  function logOutOfGallery() {
    const legacyID = gallery._id;
    api.post("/api/gallery/logoutGallery/").then(res => {
      reduxDispatch(logoutGallery());
      navigate(`/${legacyID}`, { replace: true });
      // Remove all states from session storage on logout
      sessionStorage.removeItem("app_state");

    }).catch(e => {
      console.debug(e);
      navigate(`/${legacyID}`, { replace: true });
      // Remove all states from session storage on logout
      sessionStorage.removeItem("app_state");
    });
  }

  /**
 * Log the current user out and reset the global state
 */
  function logoutUserFunc() {
    api.post("/api/user/logout").then(res => {
      reduxDispatch(logoutUser());
      navigate(`/pro/login`, { replace: true });
      // Remove all states from session storage on logout
      sessionStorage.removeItem("app_state");
    }).catch(e => {
      console.debug(e);
      navigate(`/pro/login`, { replace: true });
      // Remove all states from session storage on logout
      sessionStorage.removeItem("app_state");
    });
  }

  /**
   * Workaround to include an icon in the download dropdown toggle button
   */
  const downloadToggle = (<i className="bi bi-download bi-button text-dark"></i>);
  const [prepDownload, setPrepDownload] = useState(false);

  /**
   * Handle the send my favourites modal
   */
  const [show, setShow] = useState(false);

  /**
   * Handle the error alert
   */
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  /**
   * Close the error alert
   */
  function closeErrorAlert() {
    setShowErrorAlert(false);
  }

  /**
   * Show the send my favourites modal
   */
  const sendFavourites = () => {
    // If for some reasons the user was able to select more favourites than the hard limit allows, abort and pop an alert to warn them
    if (gallery?.params?.hardMaxFavourites && gallery?.params.maxFavourites < gallery?.favourites?.length) {
      setErrorText('errors.MAX_FAVS_REACHED');
      setShowErrorAlert(true);
      return;
    } if (!gallery?.favourites?.length) {
      setErrorText('errors.NO_FAV_SELECTED');
      setShowErrorAlert(true);
      return;
    } else {
      setShow(true);
    }
  };

  /**
   * Close the send my favourites modal
   */
  const closeFavouritesAlert = () => { setShow(false); };

  /**
   * Zip all relevant files (favourites or all) and download them
   * @param {boolean} favsOnly are we downloading only the favourites or all pictures?
   */
  async function triggerDownload(favsOnly: boolean) {
    setPrepDownload(true);
    const filesToDownload = favsOnly ? gallery?.favourites : gallery?.imgs;
    const zip = new JSZip();

    let failed = false;

    await new Promise<any>((resolve) => {
      let i = 1;
      filesToDownload?.forEach(async (file: any) => {
        let baselog;
        try {
          baselog = await getBase64(file.url).catch(err => {
            throw new Error(err);
          });
        } catch (err2: any) {
          openConfirmationToast("download.error", true);
          setPrepDownload(false);
          failed = true;
        }
        if (baselog) {
          zip.file(file.name + '.' + baselog?.type, baselog?.content, { base64: true });
        }
        if (filesToDownload.length === i) {
          resolve('');
        } else { i++; }
      });
    });

    if (!failed) {
      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          fileSaver.saveAs(content, gallery?.name + ".zip");
          setPrepDownload(false);
        });

    }




  }

  /**
   * Update favsStatus store object
   */
  function toggleFavs() {
    favsStatus ? reduxDispatch(toggleFavsOff()) : reduxDispatch(toggleFavsOn());
  }

  function getWebsite() {
    // user?.userParams?.website || 
    return gallery?.owner?.userParams?.website || (user?._id ? (APP_DOMAIN + "/pro/home") : "https://lokasennas.com");
  }

  function getLogo() {
    // user?.userParams?.logo || 
    // For now, always return the lokasennas logo when in user space
    return gallery?.owner?.userParams?.logo || (user ? "/img/logo.png" : "");
  }

  function getBrandName() {
    // user?.userParams?.brandName || 
    // For now, always return Lokasennas Photography when in user space
    return gallery?.owner?.userParams?.brandName;
  }

  function favsCount() {
    return gallery?.favourites && gallery.favourites.length > gallery?.params?.maxFavourites && gallery?.params?.maxFavourites > 0;
  }

  function favsUnderCount() {
    return gallery?.favourites && gallery.favourites.length < gallery?.params?.maxFavourites && gallery?.params?.maxFavourites > 0;
  }

  /**
  * Manage the confirmation toast
  */
  const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

  /**
   * Close the confirmation toast
   */
  function closeConfirmationToast() {
    const newToast = Object.assign({}, confirmationToastManager);
    newToast.open = false;
    setChangeConfirmationToastManager(newToast);
  }

  /**
  * Pop a confirmation toast
  */
  function openConfirmationToast(type: string, error = false) {
    setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
  }

  return (
    <>
      <Navbar expand="sm">

        <Navbar.Brand href={getWebsite() || ((getLogo() || getBrandName()) ? '' : 'https://lokasennas.com')} target={user?._id ? "" : "_blank"}><img
          alt=""
          src={getLogo() || (getWebsite() ? '' : '/img/logo.png')}
          width="auto"
          height="50"
          className="d-inline-block align-top"
        />{getLogo() ? " " : getBrandName() ? getBrandName() : " "}</Navbar.Brand>
        {gallery && gFullyLoaded &&
          <Navbar.Text className="me-auto title">
            {gallery?.name}
          </Navbar.Text>}
        {gallery && gFullyLoaded &&
          <Nav>
            {gallery?.params?.download && !prepDownload &&
              <Button className="icon-button">
                <NavDropdown align={{ sm: 'end' }} title={downloadToggle} id="navbarScrollingDropdown">

                  <NavDropdown.Item onClick={() => triggerDownload(false)}>{t("menu.downloadAll")}</NavDropdown.Item>
                  {gallery?.favourites?.length > 0 && <NavDropdown.Item onClick={() => triggerDownload(true)} href="#action3">{t("menu.downloadFavs")}</NavDropdown.Item>}
                </NavDropdown>
              </Button>
            }
            {gallery?.params?.download && prepDownload && <Button className="icon-button"><Spinner animation="border" size="sm" /></Button>}

            {gallery?.params?.sendFavs && !gallery?.params?.isLocked && gallery?.password && <Button onClick={sendFavourites} className="icon-button"><i className="bi bi-button bi-send"></i></Button>}

            <Button onClick={toggleFavs} className="float-badge icon-button">{favsStatus && <i className="bi  bi-button bi-heart-fill"></i>}{!favsStatus && <i className="bi bi-button bi-heart"></i>}{gallery?.favourites?.length > 0 && <Badge pill bg={favsCount() ? "danger" : "primary"} text={favsCount() ? "light" : "dark"} >{(gallery?.favourites?.length) + (gallery?.params?.maxFavourites > 0 ? `/${gallery?.params?.maxFavourites}` : '')}</Badge>}</Button>
            {gallery?.password && <Button onClick={logOutOfGallery} className="icon-button"><i className="bi bi-button bi-box-arrow-right"></i></Button>}

          </Nav>
        }
        {user && user._id &&
          <><Navbar.Text className="me-auto title">
          </Navbar.Text><Nav>
              <Button href="/pro/home" className="icon-button user-button"><i className="bi  bi-button bi-images"></i></Button>
              <Button href="/pro/settings" className="icon-button user-button"><i className="bi  bi-button bi-gear"></i></Button>
              <Button className="icon-button user-button" onClick={logoutUserFunc}><i className="bi bi-button bi-box-arrow-right"></i></Button>
            </Nav></>
        }

      </Navbar>

      <ErrorAlert handleClose={closeErrorAlert} isOpen={showErrorAlert} text={errorText}></ErrorAlert>
      <SendFavsAlert name={gallery?.owner?.userParams?.brandName || gallery?.owner?.username || t("alerts.yourPhotographer")} isOpen={show} sendTo={gallery?.owner?.email} isOverLimit={favsCount()} isUnderLimit={favsUnderCount()} maxNumber={gallery?.params?.maxFavourites} favsNumber={gallery?.favourites?.length} handleClose={closeFavouritesAlert} toastManager={openConfirmationToast}></SendFavsAlert>
      <ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>

    </>
  );
};

export default NavBarComponent;