import { galleryImage, galleryParams } from "../../Utils/types";
import Image from 'react-bootstrap/Image';
import './ImageCard.scss';
import { useState } from "react";
import { downloadImage } from "../../Services/DownloadService";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { addFav, delFav } from "../../redux/actions/galleryActions";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";


function ImageCard(props: { totalFavs?: number | undefined, params?: galleryParams | undefined, img?: galleryImage, index?: number, clickAction?: any, toastManager?: any }) {
    const [hover, setHover] = useState(false);
    const [wasDownloaded, setWasDownloaded] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const reduxDispatch = useDispatch();
    const gallery = useSelector((state: any) => state.gallery?.gallery);
    const [clickGuard, setclickGuard] = useState(false);
    const { t } = useTranslation();

    function handleDownloadImage(e: any) {
        setWasDownloaded(true);
        downloadImage(e, props?.img).catch(err => {
            props?.toastManager("download.error", true);
        });
    }


    function triggerFav(e: any) {
        e.stopPropagation();
        setclickGuard(true);
        if (clickGuard) {
            return;
        }
        if ((!props?.img?.isFav && props?.totalFavs && props?.params?.hardMaxFavourites && props?.params.maxFavourites <= props?.totalFavs && props?.params.maxFavourites > 0) || props?.params?.isLocked) {
            setclickGuard(false);
            setShowErrorAlert(true);
        } else {
            gallery.password ? (props?.img?.isFav ?
                api.put("api/gallery/removeFav/" + props?.img?._id).then(res => {
                    reduxDispatch(delFav(res.data));
                    setclickGuard(false);
                }).catch(e => {
                    setclickGuard(false);
                    props?.toastManager("removeFav.error", true);

                })
                :
                api.put("api/gallery/addFav/" + props?.img?._id).then(res => {
                    const imgWithUrl = Object.assign({}, res.data);
                    imgWithUrl.url = props?.img?.url;
                    reduxDispatch(addFav(imgWithUrl));
                    setclickGuard(false);
                }).catch(e => {
                    setclickGuard(false);
                    props?.toastManager("addFav.error", true);

                })) :
                (props?.img?.isFav ?
                    api.put("api/gallery/removeFavNoPass/" + gallery._id + '/' + props?.img?._id).then(res => {
                        reduxDispatch(delFav(res.data));
                        setclickGuard(false);
                    }).catch(e => {
                        setclickGuard(false);
                        props?.toastManager("removeFav.error", true);

                    })
                    :
                    api.put("api/gallery/addFavNoPass/" + gallery._id + '/' + props?.img?._id).then(res => {
                        const imgWithUrl = Object.assign({}, res.data);
                        imgWithUrl.url = props?.img?.url;
                        reduxDispatch(addFav(imgWithUrl));
                        setclickGuard(false);
                    }).catch(e => {
                        setclickGuard(false);
                        props?.toastManager("addFav.error", true);

                    }))
                ;
        }
    }

    function closeErrorAlert() {
        setShowErrorAlert(false);
    }

    return <>
        <ErrorAlert handleClose={closeErrorAlert} isOpen={showErrorAlert} text={props?.params?.isLocked ? (t("errors.GALLERY_LOCKED", { name: gallery?.owner?.userParams?.brandName, email: gallery?.owner?.email })) : 'errors.MAX_FAVS_REACHED'}></ErrorAlert>
        <div className="image-card-wrapper pointer" >
            {props?.img?.isFav && !hover && <div className="fav-icon" onClick={(e) => { isMobile ? triggerFav(e) : ''; }}><i className="bi bi-heart-fill"> </i></div>}
            {!isMobile && <Image onMouseEnter={() => setHover(true)}
                className="gal-img" src={props?.img?.url} />}
            {isMobile && <Image onClick={() => props?.clickAction(props?.index)}
                className="gal-img" src={props?.img?.url} />}
            {hover && <div className="hover" onMouseLeave={() => setHover(false)} onClick={() => props?.clickAction(props?.index)}>
                <div className="hover-options" >
                    {props?.params?.download && <i className={wasDownloaded ? "bi bi-button bi-download pointer was-downloaded" : "bi bi-button bi-download pointer"} onClick={(e) => handleDownloadImage(e)}></i>}
                    {props?.img?.isFav && <i className="bi bi-button bi-heart-fill pointer" onClick={(e) => triggerFav(e)}></i>}
                    {!props?.img?.isFav && <i className="bi bi-button bi-heart pointer" onClick={(e) => triggerFav(e)}></i>}
                </div>
            </div>
            }
        </div>
    </>;
}

export default ImageCard;