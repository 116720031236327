import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import './CreateGalleryModal.scss';
import api from "../../api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateGalleryList } from "../../redux/actions/userActions";
import HelpTooltip from "../HelpTooltip/HelpTooltip";
import { TITLE_LENGTH } from "../../config/config";

function CreateGalleryModal(props: { isOpen: boolean, handleClose: any, toastManager: any }) {
    const [show, setShow] = useState(Boolean);
    const [newGallery, setNewGallery] = useState<any>({ name: "", description: "", password: "", params: { sendFavs: true, download: false, watermark: true, hardMaxFavourites: false, maxFavourites: 0 } });
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    /**
 * Handle the required fields's validity status
 */
    const [validTitle, setValidTitle] = useState(true);
    const [validMax, setValidMax] = useState(true);
    const [maxFavsError, setMaxFavsError] = useState<string>("createGallery.needMaxFavs");

    /**
     * Create the gallery
     */
    function create(event: any) {
        if (!newGallery.name || (newGallery.params.hardMaxFavourites && Number(newGallery.params.maxFavourites) < 1)) {
            event.preventDefault();
            event.stopPropagation();
            setValidTitle(!!newGallery.name);
            setValidMax(!(newGallery.params.hardMaxFavourites && !newGallery.params.maxFavourites));
        } else if (Number(newGallery.params.maxFavourites) < 0) {
            setValidMax(false);
            setMaxFavsError("createGallery.needValidNumber");
            return;
        } else {
            api.post("api/galleryAdmin/createGallery", { name: newGallery.name, password: newGallery.password, description: newGallery.description, params: newGallery.params }).then((res: any) => {
                reduxDispatch(updateGalleryList(res.data));
                props?.handleClose();
                navigate(`/pro/gallery/${res.data._id}`);
            }).catch(e => {
                props?.toastManager("createGallery.error", true);
            });
        }
    }

    function handleChange(data: any, type: any, subType?: any) {
        const newGalCopy = newGallery;
        if (subType) {
            newGalCopy[type][subType] = data;
        }
        else {
            newGalCopy[type] = data;
        }
        setNewGallery(newGalCopy);
        if (subType === "hardMaxFavourites") {
            const doc = document.getElementById("hard-max-required-1");
            if (doc) {
                newGallery.params.hardMaxFavourites ? doc.style.display = "inline" : doc.style.display = "none";
            }
        }
    }


    return (
        <>
            <Modal show={show} onHide={props?.handleClose} className="top-alert gallery-form-styles">
                <Modal.Header>{t("createGallery.modalTitle")}</Modal.Header>
                <Form noValidate>
                    <Modal.Body>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputTitle">{t("general.title")} <span className="required">({t("general.required")})</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="inputTitle"
                                required
                                onChange={(e) => handleChange(e.target.value, "name")}
                                aria-describedby="titleHelpBlock"
                                placeholder={t("createGallery.enterTitle")}
                                maxLength={TITLE_LENGTH}
                                isInvalid={!validTitle}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("createGallery.needTitle")}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputPassword">{t("general.password")}</Form.Label>
                            <Form.Control
                                type="password"
                                id="inputPassword"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "password")}
                                placeholder={t("createGallery.enterPassword")}
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputDescription">{t("general.description")}</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="inputDescription"
                                aria-describedby="descriptionHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "description")}
                                placeholder={t("createGallery.enterDescription")}
                                maxLength={300}
                            />
                        </Form.Group>

                        <Form.Check
                            type="switch"
                            id="sendFavs"
                        >
                            <Form.Check.Input
                                id="send-favs-input"
                                defaultChecked={true}
                                onChange={(e) => handleChange(e.target.checked, "params", "sendFavs")}
                            />
                            <Form.Check.Label className="label-with-help">
                                {t("createGallery.sendFavourites")}
                                <HelpTooltip text={t("userGallery.help.sendFavs")}></HelpTooltip>
                            </Form.Check.Label>
                        </Form.Check>

                        <Form.Check
                            type="switch"
                            id="download"
                        >
                            <Form.Check.Input
                                id="dowload-input"
                                onChange={(e) => handleChange(e.target.checked, "params", "download")}
                            />
                            <Form.Check.Label>
                                {t("createGallery.allowDownloads")}
                            </Form.Check.Label>
                        </Form.Check>

                        <Form.Check
                            type="switch"
                            id="watermark"

                        >
                            <Form.Check.Input
                                id="watermark-input"
                                defaultChecked={true}
                                onChange={(e) => handleChange(e.target.checked, "params", "watermark")}
                            />
                            <Form.Check.Label>
                                {t("createGallery.applyWatermark")}
                                <HelpTooltip text={t("userGallery.help.watermark")}></HelpTooltip>
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check
                            type="switch"
                            id="hardMaxFavourites"
                        >
                            <Form.Check.Input
                                id="hard-max-input"
                                onChange={(e) => handleChange(e.target.checked, "params", "hardMaxFavourites")} />
                            <Form.Check.Label className="label-with-help">
                                {t("createGallery.allowHardMax")}
                                <HelpTooltip text={t("userGallery.help.hardMaxFavs")}></HelpTooltip>
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={9} className="label-with-help">
                                {t("createGallery.maxFavourites")}
                                <span className="required" id="hard-max-required-1">({t("general.required")})</span>
                                <HelpTooltip text={t("userGallery.help.maxFavs")} alignment="left"></HelpTooltip>
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" placeholder="0" onChange={(e) => handleChange(e.target.value, "params", "maxFavourites")}
                                    isInvalid={!validMax}
                                    required={newGallery.params?.hardMaxFavourites}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(maxFavsError ? maxFavsError : "createGallery.needMaxFavs")}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={props?.handleClose}>
                            {t("general.cancel")}
                        </Button>
                        <Button variant="primary" onClick={(e) => create(e)} >
                            {t("general.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CreateGalleryModal;