import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import './ErrorAlert.scss';

function ErrorAlert(props: { isOpen: boolean, handleClose: any, text: any }) {
    const [show, setShow] = useState(Boolean);
    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    return (
        <>
            <Modal show={show} onHide={props.handleClose} className="top-alert">
                <Modal.Body>{t(props?.text)}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props?.handleClose}>
                        {t("general.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ErrorAlert;