import { useEffect, useState } from 'react';
import { ToastBody, ToastContainer, ToastHeader } from 'react-bootstrap';
import './ConfirmationToast.scss';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';

function ConfirmationToast(props: { isOpen: boolean, handleClose: any, body?: string, head?: string, error?: boolean }) {
    const { t } = useTranslation();
    const [show, setShow] = useState(Boolean);

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    return (
        <ToastContainer
            position="bottom-center"
            style={{ zIndex: 1200 }}
            containerPosition='fixed'
        >
            <Toast onClose={props?.handleClose} show={show} delay={3500} autohide bg={props?.error ? "danger" : ""}>
                <ToastHeader closeButton={true}>
                    <strong className="me-auto">{t(props?.head ? props.head : "feedback.genericTitle")}</strong>
                </ToastHeader>
                <ToastBody>{t(props?.body ? props.body : "feedback.genericToastBody")}</ToastBody>
            </Toast>
        </ToastContainer>
    );
}

export default ConfirmationToast;