import Color from "color";
import { MAX_FILE_SIZE } from "../config/config";

/**
 * Change the bs primary color value and its derivatives to the provided new color (in hex form)
 * @param string : new theme color, in hex fom
 */
export const swapTheme = (newPrimary: string) => {
    const color = Color(newPrimary);
    document.documentElement.style.setProperty('--bs-primary', newPrimary);
    document.documentElement.style.setProperty('--bs-primary-light', color.lighten(0.2).toString());
    document.documentElement.style.setProperty('--bs-primary-rgb', hexToRgb(newPrimary));
};

/**
 * A custom function to convert an hex into a string of rgb numbers, to be fed into a specific bs variable...
 * @param hex A hex color value
 * @returns string
 */
function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
    return rgb ? String(rgb.r + "," + rgb.g + "," + rgb.b) : null;
}

export function validateFileType(type: string) {
    return !!(type === "image/jpeg" || type === "image/png");
}

export function validateFileSize(size: number) {
    return !!(size < MAX_FILE_SIZE);
}

