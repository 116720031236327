import { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { galleryImage, galleryParams } from "../../Utils/types";
import './FullOverlay.scss';
import { downloadImage } from "../../Services/DownloadService";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import api from "../../api";
import { addFav, delFav } from "../../redux/actions/galleryActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateActiveGallery } from "../../redux/actions/userActions";


function FullOverlay(props: { totalFavs?: number | undefined, params?: galleryParams | undefined, cardsList: galleryImage[] | undefined, currentIndex: number, handleClose: any, userGal?: boolean | undefined, toastManager?: any }) {
    const [imgIndex, setImgIndex] = useState(0);
    const [shadowImgIndex, setShadowImgIndex] = useState<number>();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const reduxDispatch = useDispatch();
    const gallery = props?.userGal ? useSelector((state: any) => state.user?.activeGallery) : useSelector((state: any) => state.gallery?.gallery);
    const [clickGuard, setclickGuard] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setImgIndex(shadowImgIndex ? shadowImgIndex : props?.currentIndex);
        const offsetY = window.scrollY;
        const overlay = document.getElementById("fullOverlay");
        if (overlay?.style) {
            overlay.style.top = offsetY + "px";
        }
    }, [props?.currentIndex]);


    window.addEventListener('keyup', (e) => { listenToKey(e); });
    window.addEventListener('touchstart', handleTouchStart, false);
    window.addEventListener('touchmove', handleTouchMove, false);

    let xDown: any = null;

    function getTouches(evt: any) {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt: any) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
    }

    function handleTouchMove(evt: any) {
        if (!xDown) {
            return;
        }

        const xUp = evt.touches[0].clientX;

        const xDiff = xDown - xUp;

        if (xDiff > 0) {
            navigateForward();
        } else {
            navigateBack();
        }

        /* reset values */
        xDown = null;
    }

    function listenToKey(e: KeyboardEvent) {
        e?.stopPropagation();

        if (e.repeat) { return; }

        if (e.key === 'ArrowLeft') {
            navigateBack();
        } else if (e.key === 'ArrowRight') {
            navigateForward();
        } else if (e.key === 'Escape') {
            closeOverlay();
        }
    }
    async function navigateBack(e?: any) {

        e?.stopPropagation();

        if (imgIndex > 0) {
            // await slideImg('right');
            setImgIndex(imgIndex - 1);
            setShadowImgIndex(imgIndex - 1);
        } else { return; }

    }

    async function navigateForward(e?: any) {
        e?.stopPropagation();
        if (props?.cardsList?.length !== undefined && props?.cardsList?.length > (imgIndex + 1)) {
            // await slideImg('left');
            setImgIndex(imgIndex + 1);
            setShadowImgIndex(imgIndex + 1);

        } else {
            return;
        }
    }

    function triggerFav(e: any) {
        e.stopPropagation();
        setclickGuard(true);
        if (clickGuard) {
            return;
        }
        if ((!(props?.cardsList && props?.cardsList[imgIndex]?.isFav) && props?.totalFavs && props?.params?.hardMaxFavourites && props?.params.maxFavourites <= props?.totalFavs && props?.params.maxFavourites > 0) || props?.params?.isLocked) {
            setShowErrorAlert(true);
        } else {
            let img = '';
            if (props?.cardsList && props.cardsList[imgIndex]?._id) {
                img = props?.cardsList && props.cardsList[imgIndex]?._id;
            }
            gallery.password ? (props?.cardsList && props?.cardsList[imgIndex]?.isFav ?
                api.put("api/gallery/removeFav/" + img).then(res => {
                    reduxDispatch(delFav(res.data));
                    setclickGuard(false);
                }).catch(e => {
                    props?.toastManager("removeFav.error", true);
                })
                :
                api.put("api/gallery/addFav/" + img).then(res => {
                    const imgWithUrl = Object.assign({}, res.data);
                    imgWithUrl.url = props?.cardsList && props.cardsList[imgIndex]?.url;
                    reduxDispatch(addFav(imgWithUrl));
                    setclickGuard(false);
                }).catch(e => {
                    props?.toastManager("addFav.error", true);
                })) :
                (props?.cardsList && props?.cardsList[imgIndex]?.isFav ?
                    api.put("api/gallery/removeFavNoPass/" + gallery._id + '/' + img).then(res => {
                        reduxDispatch(delFav(res.data));
                        setclickGuard(false);
                    }).catch(e => {
                        props?.toastManager("removeFav.error", true);

                    })
                    :
                    api.put("api/gallery/addFavNoPass/" + gallery._id + '/' + img).then(res => {
                        const imgWithUrl = Object.assign({}, res.data);
                        imgWithUrl.url = props?.cardsList && props.cardsList[imgIndex]?.url;
                        reduxDispatch(addFav(imgWithUrl));
                        setclickGuard(false);
                    }).catch(e => {
                        props?.toastManager("addFav.error", true);

                    }))
                ;
        }
    }

    function closeOverlay() {
        window.removeEventListener('keyup', (e) => { //
        });
        props?.handleClose();
    }

    async function slideImg(direction: string) {
        await new Promise<any>((resolve) => {
            const elem = document.getElementById("imgFull");
            direction === 'left' ? elem?.animate([
                { marginLeft: '0px' },
                { marginLeft: '-3000px' },

            ], {
                duration: 500,
                fill: 'backwards'
            }) : direction === 'right' ? elem?.animate([
                { marginRight: '0px' },
                { marginRight: '-3000px' },

            ], {
                duration: 500,
                fill: 'backwards'
            }) : '';
            setTimeout(() => { resolve(''); }, 400);

        });
    }

    function closeErrorAlert() {
        setShowErrorAlert(false);
    }

    function setCover(e: any) {
        e.stopPropagation();
        console.debug(gallery);
        let img = '';
        if (props?.cardsList && props.cardsList[imgIndex]?._id) {
            img = props?.cardsList && props.cardsList[imgIndex]?._id;
        }
        api.put("api/galleryAdmin/editGalleryCover/" + gallery?._id, { imgID: img === gallery?.cover?._id ? null : img }).then(res => {
            reduxDispatch(updateActiveGallery({ key: "cover", data: res.data }));
        }).catch(e => {
            props?.toastManager("coverChange.error", true);

        });

    }

    return <>
        <ErrorAlert handleClose={closeErrorAlert} isOpen={showErrorAlert} text={props?.params?.isLocked ? (t("errors.GALLERY_LOCKED", { name: gallery?.owner?.userParams?.brandName, email: gallery?.owner?.email })) : 'errors.MAX_FAVS_REACHED'}></ErrorAlert>
        <div className="full-overlay" id="fullOverlay">
            <Container fluid>
                <Row>
                    <Col>
                        <Button className="icon-button" onClick={closeOverlay}><i className="bi bi-x pointer" ></i></Button>
                    </Col>
                    <Col className="text-align-end options">
                        {!props?.userGal && props?.params?.download && <Button className="icon-button" onClick={(e) => downloadImage(e, props?.cardsList && props?.cardsList[imgIndex]).catch(err => {
                            props?.toastManager("download.error", true);
                        })}><i className="bi bi-download pointer" ></i></Button>}
                        {!props?.userGal && props?.cardsList && props?.cardsList[imgIndex]?.isFav && <Button className="icon-button" onClick={(e) => triggerFav(e)}><i className="bi bi-heart-fill pointer" ></i></Button>}
                        {!props?.userGal && !(props?.cardsList && props?.cardsList[imgIndex]?.isFav) && <Button className="icon-button" onClick={(e) => triggerFav(e)}><i className="bi bi-heart pointer" ></i></Button>}
                        {props?.userGal && <Button className="icon-button" onClick={(e) => { setCover(e); }}><i className={props?.cardsList && props.cardsList[imgIndex]?._id === gallery?.cover?._id ? "bi bi-image-fill" : "bi bi-image"}> </i></Button>}
                    </Col>
                </Row >
                <Row className="align-items-center">
                    <Col sm={1} className="d-none d-sm-block">{imgIndex > 0 && <Button className="icon-button" onClick={(e) => navigateBack(e)}><i className="bi bi-button bi-chevron-compact-left pointer" ></i></Button>}</Col>
                    <Col sm={10} className="col-12 full-img-wrapper">
                        <img className="full-img" id={'imgFull'} src={props?.cardsList && props?.cardsList[imgIndex]?.url} />
                        <Row className="align-items-center small-screen-nav">
                            <Col className="d-block d-sm-none">{imgIndex > 0 && <Button className="icon-button" onClick={(e) => navigateBack(e)}><i className="bi bi-button bi-chevron-compact-left pointer" ></i></Button>}</Col>
                            <Col className="d-block d-sm-none text-align-end">{props?.cardsList?.length !== undefined && props?.cardsList?.length > (imgIndex + 1) && <Button className="icon-button" onClick={(e) => navigateForward(e)}><i className="bi bi-button bi-chevron-compact-right pointer"></i></Button>}</Col>

                        </Row>
                    </Col>
                    <Col sm={1} className="d-none d-sm-block text-align-end">{props?.cardsList?.length !== undefined && props?.cardsList?.length > (imgIndex + 1) && <Button className="icon-button" onClick={(e) => navigateForward(e)}><i className="bi bi-button bi-chevron-compact-right pointer"></i></Button>}</Col>

                </Row>

                <Row className="img-title">
                    <Col>
                        {props?.cardsList && props.cardsList[imgIndex]?.name}
                    </Col>
                </Row>
            </Container >
        </div >
    </>;
}

export default FullOverlay;