import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./GeneralHome.scss";
import { APP_DOMAIN } from "../../config/config";
import { Button, Carousel, Col, Form, Row } from "react-bootstrap";
import api from "../../api";
import { useState } from "react";
import ConfirmationToast from "../../Components/Toasts/ConfirmationToast/ConfirmationToast";

function GeneralHomePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function goToGal(e: any) {
        navigate(`/gallery/${e.target[0].value}`);
    }

    function sendMail(e: any) {
        const name = e.target[0].value;
        const email = e.target[1].value;
        const request = e.target[2].value;
        api.post('api/user/sendTestRequest', { name: name, email: email, request: request }).then(res => {
            openConfirmationToast("sendTestRequest", true);
        }).catch(e => {
            console.debug(e);
            openConfirmationToast("sendTestRequest.error", true);
        });
    }

    /**
   * Manage the confirmation toast
   */
    const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

    /**
     * Close the confirmation toast
     */
    function closeConfirmationToast() {
        const newToast = Object.assign({}, confirmationToastManager);
        newToast.open = false;
        setChangeConfirmationToastManager(newToast);
        console.debug(confirmationToastManager, "2");
    }

    /**
    * Pop a confirmation toast
    */
    function openConfirmationToast(type: string, error = false) {
        setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
        console.debug(confirmationToastManager, "3");

    }

    return <>
        <ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>
        <div id="firstPane">
            <div className="cover-wrap">
                <Carousel fade>
                    <Carousel.Item>
                        <img src="/img/screenshots/screen3.jpg" />
                        <Carousel.Caption>

                            <p>{t("generalHome.carousel.createGalleries")}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/img/screenshots/screen2.jpg" />
                        <Carousel.Caption>

                            <p>{t("generalHome.carousel.finetuneGalleries")}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/img/screenshots/screen1.jpg" />
                        <Carousel.Caption>

                            <p>
                                {t("generalHome.carousel.browseGallery")}
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <div className="cover-text-wrap">
                    <div className="c-row cover-text">
                        <Button variant="outline-primary" className="inner-nav-btn" href="#photographer">{t("generalHome.photographer.title")}</Button>
                        <Button variant="outline-primary" className="inner-nav-btn" href="#beta">{t("generalHome.test.title")}</Button>
                        <Button variant="outline-primary" className="inner-nav-btn" href="#photographerCustomer">{t("generalHome.customer.title")}</Button>
                    </div>
                </div>
            </div>
        </div>



        <div id="photographerPane">
            <div id="photographer">
            </div>
            <div className="pane-wrap2">

                <h2>{t("generalHome.photographer.title")}</h2>

                <p>{t("generalHome.photographer.intro")}</p>
                <p>{t("generalHome.photographer.futureFeatures")}</p>
                <div dangerouslySetInnerHTML={{ __html: t("generalHome.photographer.futureFeaturesList") }}></div>
            </div>
        </div>

        <div id="betaPane">
            <div id="beta">
            </div>
            <div className="pane-wrap3">
                <Row>
                    <Col sm={12} lg={6}>

                        <h2>{t("generalHome.test.title")}</h2>
                        <p>{t("generalHome.test.intro")}</p>
                        <p>{t("generalHome.photographer.testGal")}
                            <a href={APP_DOMAIN + "/65986b0843c1d83c19551306"} target="_blank" rel="noreferrer">{APP_DOMAIN + "/65986b0843c1d83c19551306"}</a>

                        </p>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form onSubmit={sendMail}>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="inputName">{t("generalHome.test.yourName")}</Form.Label>
                                <Form.Control
                                    id="inputName"
                                    required
                                    placeholder={t("generalHome.test.enterYourName")}
                                />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="inputEmail">{t("generalHome.test.yourEmail")}</Form.Label>
                                <Form.Control
                                    id="inputEmal"
                                    required
                                    placeholder={t("generalHome.test.enterYourEmail")}
                                />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="inputMessage">{t("generalHome.test.yourMessage")}</Form.Label>
                                <Form.Control
                                    id="inputMessage"
                                    as="textarea"
                                    required
                                    placeholder={t("generalHome.test.enterYourMessage")}
                                />
                            </Form.Group>

                            <Button type="submit">{t("generalHome.test.sendRequest")}</Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div >

        <div id="photographerCustomerPane">
            <div id="photographerCustomer">
            </div>
            <div className="pane-wrap2">
                <h2>{t("generalHome.customer.title")}</h2>
                <p>{t("generalHome.customer.intro")}</p>
                <Form onSubmit={goToGal}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="inputGalleryId">{t("generalHome.customer.galleryId")}</Form.Label>
                        <Form.Control
                            id="inputGalleryId"
                            required
                            placeholder={t("generalHome.customer.enterGalleryId")}
                        />
                    </Form.Group>
                    <Button type="submit" variant="outline-primary">{t("generalHome.customer.goToGal")}</Button>
                </Form>

            </div>
        </div>
    </>;
}

export default GeneralHomePage; 