import { galleryImage, galleryParams, IMG_DEL } from "../../Utils/types";
import Image from 'react-bootstrap/Image';
import './UserImageCard.scss';
import { useState } from "react";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { setUserGallery, updateActiveGallery, updateActiveGalleryImages } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";
import { TITLE_LENGTH } from "../../config/config";
import ConfirmAlert from "../ConfirmAlert/ConfirmAlert";


function UserImageCard(props: { totalFavs?: number | undefined, params?: galleryParams | undefined, img?: galleryImage, index?: number, clickAction?: any, toastManager?: any }) {
    const [hover, setHover] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState("");
    const reduxDispatch = useDispatch();
    const gallery = useSelector((state: any) => state.user?.activeGallery);
    const { t } = useTranslation();


    function setCover(e: any) {
        e.stopPropagation();
        api.put("api/galleryAdmin/editGalleryCover/" + gallery?._id, { imgID: props?.img?._id === gallery?.cover?._id ? null : props?.img?._id }).then(res => {
            reduxDispatch(updateActiveGallery({ key: "cover", data: res.data }));
        }).catch(e => {
            props?.toastManager("coverChange.error", true);

        });

    }

    function deleteImg() {
        api.put("api/galleryAdmin/deleteImages/" + gallery?._id, { imageIDs: [props?.img?._id] }).then(res => {
            console.debug(res);
            // For now, only one image can be deleted at a time, even though the api allows for bulk delete
            reduxDispatch(setUserGallery(res.data));
            setEditMode(false);
        }).catch(e => {
            props?.toastManager("delImg.error", true);
        });
    }

    function modifyName() {
        api.put("api/galleryAdmin/editImage/" + props?.img?._id, { name: newName }).then(res => {
            console.debug(res);
            reduxDispatch(updateActiveGalleryImages({ action: "update", data: res.data }));
            setEditMode(false);
        }).catch(e => {
            props?.toastManager("modImgName.error", true);
        });
    }

    function handleNameChange(string: string) {
        setNewName(string);
    }

    function openEditMode() {
        setEditMode(true);
    }

    /**
* Handle the confirm alert
*/
    const [showConfirmAlert, setShowConfimAlert] = useState(false);

    /**
     * Close the confirm alert
     */
    function closeConfirmAlert() {
        setShowConfimAlert(false);
    }

    /**
    * Open the confirm alert
    */
    function openConfirmAlert() {
        setShowConfimAlert(true);
    }

    return <>
        <ConfirmAlert handleClose={closeConfirmAlert} handleConfirm={deleteImg} isOpen={showConfirmAlert} text={"userGallery.confirmDeleteImage"} dontShowID={IMG_DEL}></ConfirmAlert>
        <Card className="user-image-card card-shadow">
            <div className="user-image-card-wrapper pointer user" >
                {(props?.img?._id === gallery?.cover?._id) && !hover && <div className="fav-icon" onClick={(e) => { isMobile ? setCover(e) : ''; }}><i className={props?.img?._id === gallery?.cover?._id ? "bi bi-image-fill" : "bi bi-image"}> </i></div>}
                {!isMobile && <Image onMouseEnter={() => setHover(true)}
                    className="gal-img" src={props?.img?.url} />}
                {isMobile && <Image onClick={() => props?.clickAction(props?.index)}
                    className="gal-img" src={props?.img?.url} />}
                {hover && <div className="hover" onMouseLeave={() => setHover(false)} onClick={() => props?.clickAction(props?.index)}>
                    <div className="hover-options" >
                        <i className={props?.img?._id === gallery?.cover?._id ? "bi bi-image-fill bi-button pointer" : "bi bi-image bi-button pointer"} onClick={(e) => setCover(e)}></i>
                    </div>
                </div>
                }
            </div>
            <Card.Body>
                <div className="c-row">
                    {!editMode && <Card.Title onClick={openEditMode} className="text end-elem-responsive">{props?.img?.name} <i className="bi bi-pencil small-icon"></i>
                    </Card.Title>}
                    {editMode && <Form.Group className="end-elem-responsive">
                        <InputGroup>
                            <Form.Control placeholder={t("userGallery.enterImageName")}
                                defaultValue={props?.img?.name}
                                onChange={(e) => handleNameChange(e.target.value)}
                                maxLength={TITLE_LENGTH}
                            />
                            <Button variant="outline-primary" className="icon-button" onClick={() => modifyName()}><i className="bi bi-check"></i></Button>
                        </InputGroup>

                    </Form.Group>}

                    <Button onClick={window.sessionStorage.getItem(IMG_DEL) ? deleteImg : openConfirmAlert} variant="outline-primary" className="icon-button"><i className="bi bi-button bi-trash"></i></Button>
                </div>
            </Card.Body>
        </Card>
    </>;
}

export default UserImageCard;