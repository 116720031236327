import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./ClientHome.scss";
import api from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import { logoutGallery, setReduxSmallGalleryState } from "../../../redux/actions/galleryActions";
import { swapTheme } from "../../../Utils/Functions";

function ClientHomePage(props: { id?: string | any }) {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [password, setPass] = useState(" ");
    // const [gallery, setGallery] = useState<any>();
    const gallery = useSelector((state: any) => state.gallery?.gallery);
    const reduxDispatch = useDispatch();



    useEffect(() => {
        /**
         * Get a light version of the gallery to display its name and cover
         */
        async function getGalleryAsync() {
            try {
                const res = await api.get("api/gallery/getGalleryShort/" + params.id);
                reduxDispatch(setReduxSmallGalleryState(res.data));
                if (!res.data.hasPassword) {
                    navigate(`/gallery/${params.id}`, { state: { id: params.id } });
                }
            } catch (e) {
                reduxDispatch(logoutGallery());
            }
        }
        getGalleryAsync();
    }, []);

    /**
     * Get the user's password input
     * @param e 
     */
    function handleChange(e: any): any {
        setPass(e.target.value);
    }

    /** 
     * Submit the password. If the login attempt was successful, navigate to the gallery's page. Otherwise update the form to display an error
     * */
    function submit(event: any) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidPass(false);
            return;
        }

        api.post("/api/gallery/loginGallery/" + params.id, { password }).then(res => {
            if (res.data) {
                navigate(`/gallery/${params.id}`, { state: { id: params.id } });
            } else {
                setValidPass(false);
            }

        }).catch(e => setValidPass(false));

    }

    /**
     * Handle the password input's validity status
     */
    const [validPass, setValidPass] = useState(true);

    /**
     * Set the gallery's cover image
     */
    const cover = document.getElementById("galleryCover");
    if (cover && cover.style) {
        cover.style.backgroundImage = "url(" + gallery?.cover?.url + ")";
    }

    if (params.id && gallery) {
        return <>
            {gallery?.cover?._id &&
                <div className="inside-header" id="galleryCover" >
                </div>
            }
            <div className={gallery?.cover?._id ? "main-content-client-home" : "main-content-client-home no-header navbar-margin-top"}>
                <div className="content-wrapper">
                    <h1>{gallery.name}</h1>
                    <Form noValidate onSubmit={submit}>
                        <Form.Label htmlFor="inputPassword">{t("general.password")}</Form.Label>
                        <Form.Control
                            type="password"
                            id="inputPassword"
                            required
                            onChange={(e) => handleChange(e)}
                            aria-describedby="passwordHelpBlock"
                            placeholder={t("clientHome.enterPassword")}
                            isInvalid={!validPass}
                        />
                        <Form.Control.Feedback type="invalid">

                            {t("clientHome.wrongPassword")}
                        </Form.Control.Feedback>
                        <div className="b-container">
                            <Button type="submit" variant="primary">{t("clientHome.accessGallery")}</Button>{" "}
                        </div>
                    </Form>
                    <p>{t("clientHome.noPassword")}<a>{gallery.owner?.email}</a></p>
                </div>
            </div>
        </>;

    } else {
        return <div className="main-content-client-home no-header navbar-margin-top">
            <div className="content-wrapper-justify-center">
                <div>
                    <img src={gallery?.owner?.userParams?.logo || '/img/logo.png'}></img>
                </div>
                <p>{t("placeholders.noGallery", { email: "contact@lokasennas.com" })}</p>
            </div>

        </div>;
    }
}

export default ClientHomePage; 