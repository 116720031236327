import { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './HelpTooltip.scss';


function HelpTooltip(props: { text?: string | undefined, alignment?: string | undefined }) {

  const ref = useRef(null);
  const renderTooltip = () => (
    <Tooltip id="tooltip" x-placement="bottom" className={props?.alignment ? 'align-tooltip-' + props?.alignment : ''}>
      {props.text}
    </Tooltip>
  );


  return (
    <>

      <div className="ref-container"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={renderTooltip}
          container={ref}
        >
          <i className="bi bi-question-circle help-icon" ref={ref}></i>
        </OverlayTrigger>

      </div>
    </>
  );
}

export default HelpTooltip;