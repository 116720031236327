import { galleryImage, galleryParams } from "../../Utils/types";
import ImageCard from "../ImageCard/ImageCard";
import './CardsCaroussel.scss';
import { useState } from "react";
import FullOverlay from "../FullOverlay/FullOverlay";
import UserImageCard from "../UserImageCard/UserImageCard";
import { Spinner } from "react-bootstrap";

function CardsCaroussel(props: { totalFavs?: number | undefined, params?: galleryParams | undefined, cardsList: galleryImage[] | undefined, userGal?: boolean | undefined, loading?: boolean | undefined, toastManager?: any }) {
    const [fullImages, setFullImages] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);

    function openImgOverlay(i: any) {
        setFullImages(true);
        setImgIndex(i);
        document.body.style.overflow = "hidden";
    }
    function closeImgOverlay() {
        setFullImages(false);
        document.body.style.overflow = "scroll";
    }

    return <>
        {fullImages && <FullOverlay totalFavs={props?.totalFavs} params={props?.params} cardsList={props?.cardsList} currentIndex={imgIndex} handleClose={closeImgOverlay} userGal={props?.userGal} toastManager={props?.toastManager} />}
        {!props?.userGal && <div className="flex-gallery">
            {
                props?.cardsList && props?.cardsList?.map((img: any, index: number) => (
                    <ImageCard totalFavs={props?.totalFavs} params={props?.params} img={img} key={index} index={index} clickAction={openImgOverlay} toastManager={props?.toastManager} />
                ))
            }
            <ImageCard />
        </div>}
        {props?.userGal && <div className="flex-gallery">
            {
                props.cardsList && props.cardsList.map((img: any, index: number) => (
                    <UserImageCard img={img} key={index} index={index} clickAction={openImgOverlay} toastManager={props?.toastManager} />
                ))
            }
            {props?.loading && <Spinner animation="border" />}

        </div>}
    </>;
}

export default CardsCaroussel;