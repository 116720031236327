import * as actionTypes from "../consts/utilsConsts";

export const toggleFavsOn = () => (dispatch: (arg0: { type: any; }) => void) => {
    dispatch({
        type: actionTypes.FAVS_ON
    });
};

export const toggleFavsOff = () => (dispatch: (arg0: { type: any; }) => void) => {
    dispatch({ type: actionTypes.FAVS_OFF });
};