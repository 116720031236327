import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../api";
import i18n from "../../i18n/config";
import { useDispatch } from "react-redux";
import { updateGalleryClient } from "../../redux/actions/galleryActions";

function SendFavsAlert(props: { name?: string | undefined, isOverLimit?: boolean | undefined, isUnderLimit?: boolean | undefined, isOpen: boolean, favsNumber: number | undefined, maxNumber: number | undefined, handleClose: any, sendTo: any, toastManager?: any }) {
    const [show, setShow] = useState(Boolean);
    const reduxDispatch = useDispatch();


    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    function send() {
        api.post('api/gallery/sendMail', { lang: i18n.language }).then(res => {
            console.debug(res);
            api.put('api/gallery/lockGallery').then(res => {
                reduxDispatch(updateGalleryClient({ key: "params", data: res.data }));
                props?.toastManager("shareFavs", false);
                props.handleClose();
            });
        }).catch(e => {
            console.debug(e);
            props?.toastManager("shareFavs.error", true);
            props.handleClose();
        });
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>{t("clientGallery.sendFavourites")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t(props?.isOverLimit ? "alerts.sendFavsOverLimit" : props?.isUnderLimit ? "alerts.sendFavsUnderLimit" : "alerts.sendFavs", { name: props.name, number: props.favsNumber, max: props.maxNumber, email: props?.sendTo })}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={props.handleClose}>
                        {t("general.cancel")}
                    </Button>
                    <Button variant="primary" onClick={send}>
                        {t(props?.isOverLimit ? "alerts.sendNonetheless" : "general.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendFavsAlert;