import { galleryImage } from "../Utils/types";
import saveAs from "file-saver";

export async function getBase64(src: any) {
    try {
        const data = await fetch(src, { cache: 'reload' });
        const blob = await data.blob();
        const type = data.headers.get("Content-Type");
        return new Promise<any>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve({
                    content: getBase64StringFromDataURL(base64data), type: type?.split('/')[1]
                });
            };
        });
    } catch (e: any) {
        console.debug(e);
        throw new Error(e);
    }
}

export function getBase64StringFromDataURL(dataURL: any) {
    return dataURL.replace('data:', '').replace(/^.+,/, '');
}

export async function downloadImage(e: any, img: galleryImage | undefined) {
    e?.stopPropagation();
    try {
        if (img) {
            const data = await fetch(img.url, { cache: 'reload' });
            const blob = await data.blob();
            const type = data.headers.get("Content-Type");
            const file = new File([blob], img.name + '.' + type?.split('/')[1]);
            saveAs(file);
        }
    } catch (e: any) {
        console.debug(e);
        throw new Error(e);
    }
}

