import { useEffect, useState } from "react";
import "./ProHome.scss";
import api from "../../../api";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser, getUserGalleries, loginUser } from "../../../redux/actions/userActions";
import { Button, Form, Spinner } from "react-bootstrap";
import GalleryCardsCaroussel from "../../../Components/GalleryCardCaroussel/GalleryCardsCaroussel";
import { galleryStatusSelector } from "../../../Utils/types";
import CreateGalleryModal from "../../../Components/CreateGalleryModal/CreateGalleryModal";
import ConfirmationToast from "../../../Components/Toasts/ConfirmationToast/ConfirmationToast";
import { isMobile } from "react-device-detect";

const ProHomePage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userGalleries = useSelector((state: any) => state.user.galleries);
    const reduxDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [galleryType, setgalleryType] = useState("all");


    /**
     * Get the full gallery or clear store and navigate back to home page in case of error
     */
    useEffect(() => {
        async function getUserGalleriesAsync() {
            setLoading(true);
            api.get("api/galleryAdmin/getUserGalleries/").then(res => {
                reduxDispatch(getUserGalleries(res.data));
                setLoading(false);
            }).catch(e => {
                reduxDispatch(logoutUser());
                // If galleries fetch error, navigate back to root
                navigate(`/pro/login`, { replace: true });
            });
        }
        async function getUserAsync() {
            api.get("api/user/getUser/").then(res => {
                reduxDispatch(loginUser(res.data));
            }).catch(e => {
                reduxDispatch(logoutUser());
                // If no user, navigate back to root
                navigate(`/pro/login`, { replace: true });
            });
        }

        getUserAsync();
        getUserGalleriesAsync();
    }, []);

    /**
     * Open the create gallery modal
     */
    function openNewGalleryModal(): any {
        setNewGalleryModal(true);
    }

    /**
 * Changes the type of gallery selected for display
 */
    function handleGalleryTypeSelect(e: any): any {
        setgalleryType(e.target.value);
    }

    /**
     * Handle the create gallery modal
     */
    const [showNewGalleryModal, setNewGalleryModal] = useState(false);

    /**
     * Close the create gallery modal
     */
    function closeNewGalleryModal() {
        setNewGalleryModal(false);
    }


    /**
* Manage the confirmation toast
*/
    const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

    /**
     * Close the confirmation toast
     */
    function closeConfirmationToast() {
        const newToast = Object.assign({}, confirmationToastManager);
        newToast.open = false;
        setChangeConfirmationToastManager(newToast);
    }

    /**
    * Pop a confirmation toast
    */
    function openConfirmationToast(type: string, error = false) {
        setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
    }

    return <div className="main-content-pro navbar-margin-top">
        <><div className="pro-home-header c-row align-top">
            <CreateGalleryModal handleClose={closeNewGalleryModal} isOpen={showNewGalleryModal} toastManager={openConfirmationToast}></CreateGalleryModal>
            <ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>
            <div className="end-elem-responsive">
                <Form.Select aria-label="Default select example" onChange={(e) => handleGalleryTypeSelect(e)} className="pointer">
                    <option value={galleryStatusSelector.ALL}>{t("galleryStatus.selection.all")}</option>
                    <option value={galleryStatusSelector.PUBLISHED}>{t("galleryStatus.selection.published")}</option>
                    <option value={galleryStatusSelector.DRAFT}>{t("galleryStatus.selection.draft")}</option>
                    <option value={galleryStatusSelector.ARCHIVED}>{t("galleryStatus.selection.archived")}</option>
                </Form.Select>
            </div>
            <Button onClick={openNewGalleryModal} variant="primary">{isMobile ? <i className='bi bi-plus'></i> : t("proHome.createNewGallery")}</Button>
        </div>
            <div className="pro-home-content">
                {loading && <Spinner animation="border" />}
                {!loading &&
                    <GalleryCardsCaroussel galleryType={galleryType} cardsList={userGalleries} toastManager={openConfirmationToast} />
                }
            </div></>
    </div>;
};

export default ProHomePage;