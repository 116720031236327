import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const FooterComponent = () => {
  const { i18n } = useTranslation();

  function changeLanguage(e: any) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <div className='footer'>
      <div className="c-row">
        <div className="end-elem-responsive">
          <p>Copyright Lokasennas 2024</p>
          <p>Site web: <a href="https://lokasennas.com" className="dark-back">lokasennas.com</a></p>
          <p>Contact: <a href="mailto:contact@lokasennas.com" className="dark-back">contact@lokasennas.com</a></p>
        </div>
        <div>
          {i18n.language !== "en" && <Button variant="link" onClick={changeLanguage} value='en' className="dark-back">See in English</Button>}
          {i18n.language !== "fr" && <Button variant="link" onClick={changeLanguage} value='fr' className="dark-back">Voir en français</Button>}
        </div>
      </div>
    </div>
  );
};


export default FooterComponent;