import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import './ChangePasswordModal.scss';
import api from "../../api";

function ChangePasswordModal(props: { isOpen: boolean, handleClose: any, toastManager: any }) {
    const [show, setShow] = useState(Boolean);
    const [passData, setPassData] = useState<any>({ oldPassword: "", newPassword: "" });

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    /**
     * Send the share email
     */
    function changePassword(event: any) {
        if (!passData?.oldPassword || !passData?.newPassword) {
            event.preventDefault();
            event.stopPropagation();
            setValidNewPass(!!passData?.newPassword);
            setValidOldPass(!!passData?.oldPassword);
            setOldPassError("settings.changePassword.needOldPass");
            setNewPassError("settings.changePassword.needNewPass");
            return;
        } if (passData?.newPassword.length < 8) {
            setValidOldPass(!!passData?.oldPassword);
            setValidNewPass(false);
            setNewPassError("settings.changePassword.newPassTooShort");
        } else {
            setValidNewPass(!!passData?.newPassword);
            setValidOldPass(!!passData?.oldPassword);
            api.put("api/user/updateUserPassword", { oldPassword: passData?.oldPassword, newPassword: passData?.newPassword }).then(res => {
                //  reduxDispatch(loginUser(res.data));
                props?.toastManager("password", false);
                props?.handleClose();

            }).catch(e => {
                console.debug(e);
                if (String(e).includes("401")) {
                    setValidOldPass(false);
                    setOldPassError("settings.changePassword.oldPassIncorrect");
                } else {
                    props?.toastManager("password.error", true);
                    props?.handleClose();
                }

            });
        }
    }

    function handleChange(data: any, type: any) {
        const newData = passData;
        newData[type] = data;

        setPassData(newData);
    }

    const [validOldPass, setValidOldPass] = useState(true);
    const [oldPassError, setOldPassError] = useState("settings.changePassword.needOldPass");
    const [validNewPass, setValidNewPass] = useState(true);
    const [newPassError, setNewPassError] = useState("settings.changePassword.needNewPass");

    return (
        <>
            <Modal show={show} onHide={props?.handleClose} className="top-alert">
                <Modal.Header>{t("settings.changePassword.modalTitle")}</Modal.Header>
                <Form noValidate>
                    <Modal.Body>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputOldPassword">{t("settings.changePassword.oldPassword")} <span className="required">({t("general.required")})</span></Form.Label>
                            <Form.Control
                                type="password"
                                id="inputOldPassword"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "oldPassword")}
                                required
                                placeholder={t("settings.changePassword.enterOldPassword")}
                                isInvalid={!validOldPass}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t(oldPassError)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputNewPassword">{t("settings.changePassword.newPassword")} <span className="required">({t("general.required")})</span></Form.Label>
                            <Form.Control
                                type="password"
                                id="inputNewPassword"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "newPassword")}
                                required
                                placeholder={t("settings.changePassword.enterNewPassword")}
                                isInvalid={!validNewPass}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t(newPassError)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={props?.handleClose}>
                            {t("general.cancel")}
                        </Button>
                        <Button variant="primary" onClick={(e) => changePassword(e)}>
                            {t("general.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ChangePasswordModal;