import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import "./ProLogin.scss";
import { useState } from "react";
import api from "../../../api";
import { useNavigate } from "react-router-dom";

const ProLoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [password, setPass] = useState(" ");
    const [email, setEmail] = useState(" ");

    /**
     * Get the user's username input
     * @param e 
     */
    function handleEmail(e: any): any {
        setEmail(e.target.value);
    }

    /**
     * Get the user's password input
     * @param e 
     */
    function handlePass(e: any): any {
        setPass(e.target.value);
    }

    /** 
     * Submit the password. If the login attempt was successful, navigate to the gallery's page. Otherwise update the form to display an error
     * */
    function submit(event: any) {
        event.preventDefault();
        event.stopPropagation();
        api.post("/api/user/loginUser/", { email, password }).then(res => {
            if (res.data) {
                navigate(`/pro/home`);
            } else {
                setValidPass(false);
            }
        }).catch(e => setValidPass(false));

    }

    /**
     * Handle the password input's validity status
     */
    const [validPass, setValidPass] = useState(true);

    return <>
        <div className="main-content-pro-login navbar-margin-top">
            <div className="content-wrapper-justify-center">
                <div>
                    <img src={"/img/logo.png"}></img>
                </div>
                <Form noValidate onSubmit={submit}>
                    <Form.Label htmlFor="inputEmail">{t("general.email")}</Form.Label>
                    <Form.Control
                        type="email"
                        id="inputEmail"
                        required
                        onChange={(e) => handleEmail(e)}
                        aria-describedby="emailHelpBlock"
                        placeholder={t("proLogin.enterEmail")}
                        isInvalid={!validPass}
                    />
                    <Form.Label htmlFor="inputPassword">{t("general.password")}</Form.Label>
                    <Form.Control
                        type="password"
                        id="inputPassword"
                        required
                        onChange={(e) => handlePass(e)}
                        aria-describedby="passwordHelpBlock"
                        placeholder={t("proLogin.enterPassword")}
                        isInvalid={!validPass}
                    />
                    <Form.Control.Feedback type="invalid">
                        {t("proLogin.wrongCredentials")}
                    </Form.Control.Feedback>
                    <div className="b-container">
                        <Button type="submit" variant="primary">{t("proLogin.login")}</Button>{" "}
                    </div>
                </Form>
            </div>
        </div>
    </>;
};

export default ProLoginPage;