export type galleryImage = {
    url: string,
    name: string,
    isFav: boolean,
    _id: string,
    tags?: string[]
}

export type galleryParams = {
    download: boolean,
    sendFavs: boolean,
    watermark: boolean,
    hardMaxFavourites: boolean,
    maxFavourites: number,
    isLocked: boolean
}

export type userParams = {
    color?: string,
    logo?: string,
    brandName?: string,
    website?: string
}

export type userInfos = {
    _id: string,
    username: string,
    email: string,
    userParam?: userParams
}

export type galleryStatus = 'published' | 'draft' | 'archived';

export enum galleryStatusSelector {
    "PUBLISHED" = "published",
    "ALL" = "all",
    "DRAFT" = "draft",
    "ARCHIVED" = "archived"
}

export type gallery = {
    _id: string,
    description: string,
    name: string,
    cover: galleryImage,
    owner: userInfos,
    params: galleryParams,
    favourites: galleryImage[],
    imgs: galleryImage[]
    password?: string,
    status?: galleryStatus
}

export const IMG_DEL = "img-del-no-confirm";
