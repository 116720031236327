import { useNavigate, useParams } from "react-router-dom";
import "./ClientGallery.scss";
import { Badge, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SendFavsAlert from "../../../Components/SendFavsAlert/SendFavsAlert";
import CardsCaroussel from "../../../Components/CardsCaroussel/CardsCaroussel";
import ErrorAlert from "../../../Components/ErrorAlert/ErrorAlert";
import api from "../../../api";
//import watermark from 'watermarkjs';
import { logoutGallery, setReduxGalleryState } from "../../../redux/actions/galleryActions";
import { useSelector, useDispatch } from "react-redux";
import { toggleFavsOn, toggleFavsOff } from "../../../redux/actions/utilsActions";
import { swapTheme } from "../../../Utils/Functions";
import ConfirmationToast from "../../../Components/Toasts/ConfirmationToast/ConfirmationToast";

function ClientGalleryPage() {
	const params = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const gallery = useSelector((state: any) => state.gallery?.gallery);
	const reduxDispatch = useDispatch();
	const favsStatus = useSelector((state: any) => state.utils?.favsStatus);
	const hasPassword = useSelector((state: any) => state.gallery?.hasPassword);
	const [errorText, setErrorText] = useState('errors.MAX_FAVS_REACHED');
	const [loading, setLoading] = useState(false);


	/**
	 * Get the full gallery or clear store and navigate back to home page in case of error
	 */
	useEffect(() => {
		async function getGalleryAsync() {
			setLoading(true);
			try {
				hasPassword ? api.get("api/gallery/getGallery/" + params.id).then(res => {
					reduxDispatch(setReduxGalleryState(res.data));
					setLoading(false);
				}).catch(e => {
					reduxDispatch(logoutGallery());
					// If no gallery, navigate back to root
					navigate(`/${params.id}`, { state: { id: params.id }, replace: true });
				}) : api.get("api/gallery/getGalleryNoPass/" + params.id).then(res => {
					reduxDispatch(setReduxGalleryState(res.data));
					setLoading(false);
				}).catch(e => {
					reduxDispatch(logoutGallery());
					// If no gallery, navigate back to root
					navigate(`/${params.id}`, { state: { id: params.id }, replace: true });
				});
			} catch (e) {
				reduxDispatch(logoutGallery());
				console.debug(e);
				// If no gallery, navigate back to root
				navigate(`/${params.id}`, { state: { id: params.id }, replace: true });
			}
		}
		getGalleryAsync();

	}, []);

	/**
	 * Update favsStatus store object
	 */
	function toggleFavs() {
		favsStatus ? reduxDispatch(toggleFavsOff()) : reduxDispatch(toggleFavsOn());
	}

	/**
	 * Handle the error alert
	 */
	const [showErrorAlert, setShowErrorAlert] = useState(false);

	/**
	 * Close the error alert
	 */
	function closeErrorAlert() {
		setShowErrorAlert(false);
	}

	/**
	 * Handle the send favs alert
	 */
	const [show, setShow] = useState(false);

	/**
	 * Let the user send (or attempt to) their favourites. If the number of favourites is over the hard limit, don't give any option to send at all and display an error alert. If it is over the soft limit, display the send alert, but with a warning
	 * @returns 
	 */
	function sendFavourites() {
		// If for some reasons the user was able to select more favourites than the hard limit allows, abort and pop an alert to warn them
		if (gallery?.params?.hardMaxFavourites && gallery?.params.maxFavourites < gallery?.favourites?.length) {
			setErrorText('errors.MAX_FAVS_REACHED');
			setShowErrorAlert(true);
			return;
		} if (!gallery?.favourites?.length) {
			setErrorText('errors.NO_FAV_SELECTED');
			setShowErrorAlert(true);
			return;
		} else {
			setShow(true);
		}
	}

	function favsCount() {
		return gallery?.favourites && gallery.favourites.length > gallery?.params?.maxFavourites && gallery?.params?.maxFavourites > 0;
	}

	function favsUnderCount() {
		return gallery?.favourites && gallery.favourites.length < gallery?.params?.maxFavourites && gallery?.params?.maxFavourites > 0;
	}

	const handleClose = () => { setShow(false); };

	/**
* Manage the confirmation toast
*/
	const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

	/**
	 * Close the confirmation toast
	 */
	function closeConfirmationToast() {
		const newToast = Object.assign({}, confirmationToastManager);
		newToast.open = false;
		setChangeConfirmationToastManager(newToast);
	}

	/**
	* Pop a confirmation toast
	*/
	function openConfirmationToast(type: string, error = false) {
		setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
	}


	return <div className="main-content-gal navbar-margin-top">
		{!favsStatus && !loading &&
			<div className="gallery-header c-row align-top">
				<div className="gallery-header-left end-elem-responsive">
					<p className="italic">{gallery?.imgs?.length} {t("clientGallery.imgs")}</p>
					<p>{gallery?.description}</p>
				</div>
				<Button onClick={toggleFavs} variant="primary">{t("clientGallery.seeFavourites")}</Button>
			</div>
		}
		{favsStatus && !loading &&
			<div className="gallery-header c-row align-top">
				<div className="gallery-header-left end-elem-responsive">
					<p className="c-row align-center"><Button className="icon-button" onClick={toggleFavs}><i className="bi bi-button bi-arrow-left pointer" ></i></Button>{t("clientGallery.favourites")}{gallery?.favourites?.length > 0 && <Badge pill text={favsCount() ? "light" : "dark"} bg={favsCount() ? "danger" : "primary"} >{gallery?.favourites?.length + (gallery?.params?.maxFavourites > 0 ? `/${gallery?.params?.maxFavourites}` : '')}</Badge>}</p>
					{favsCount() && <p>{t("errors.OVER_MAX_FAVS")}</p>}
					{gallery?.params?.isLocked && <p><span className="required">{t("errors.GALLERY_LOCKED_SHORT")}</span></p>}

				</div>
				{gallery?.password && !gallery?.params?.isLocked && gallery.params?.sendFavs && <Button variant="primary" onClick={sendFavourites}>{t("clientGallery.sendFavourites")}</Button>}

			</div>
		}
		<div className="gallery-content">
			{loading && <Spinner animation="border" />}
			{!loading && favsStatus && !gallery?.favourites?.length && <p>{t('placeholders.noFavsYet')}</p>}
			{!loading && !favsStatus && !gallery?.imgs?.length && <p>{t('placeholders.noPhotosYet')}</p>}
			{!loading && <CardsCaroussel totalFavs={gallery?.favourites?.length} params={gallery?.params} cardsList={favsStatus ? gallery?.favourites : gallery?.imgs} toastManager={openConfirmationToast} />}
		</div>
		<ErrorAlert handleClose={closeErrorAlert} isOpen={showErrorAlert} text={errorText}></ErrorAlert>
		<ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>
		<SendFavsAlert isOverLimit={favsCount()} isUnderLimit={favsUnderCount()} name={gallery?.owner?.username} sendTo={gallery?.owner?.email} maxNumber={gallery?.params?.maxFavourites} favsNumber={gallery?.favourites?.length} isOpen={show} handleClose={handleClose} toastManager={openConfirmationToast}></SendFavsAlert>
	</div>;

}

export default ClientGalleryPage;

