import * as actionTypes from "../consts/galleryConsts";
import update from 'immutability-helper';

export const galleryReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case actionTypes.LOGIN_GALLERY_SHORT:
            return {
                ...state,
                gallery: action.payload.gallery,
                hasPassword: action.payload.hasPassword,
                fullyLoaded: false
            };
        case actionTypes.LOGIN_GALLERY:
            return {
                ...state,
                gallery: action.payload,
                fullyLoaded: true
            };
        case actionTypes.UPDATE_GAL:
            return update(state, {
                gallery: {
                    [action.payload?.key]: { $set: action.payload?.data }
                }
            });
        case actionTypes.ADD_FAV:
            return update(state, {
                gallery: {
                    imgs: {
                        [state.gallery?.imgs?.indexOf(state.gallery?.imgs?.find((x: any) => x._id === action.payload?._id))]: {
                            isFav: { $set: true }
                        }
                    },
                    favourites: !state.gallery.favourites.find((x: any) => x._id === action.payload._id) ? { $push: [action.payload] } : {}
                }
            });
        case actionTypes.DEL_FAV:
            return update(state, {
                gallery: {
                    imgs: {
                        [state.gallery?.imgs?.indexOf(state.gallery?.imgs?.find((x: any) => x._id === action.payload?._id))]: {
                            isFav: { $set: false }
                        }
                    },
                    favourites: state.gallery.favourites.find((x: any) => x._id === action.payload._id) ? { $splice: [[state.gallery?.favourites?.indexOf(state.gallery?.favourites?.find((x: any) => x._id === action.payload._id)), 1]] } : {}
                }
            });
        case actionTypes.LOGOUT_GALLERY:
            return {};
        default:
            return state;
    }
};