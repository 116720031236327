import { Tab, Row, Col, Nav, Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ProSettings.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ChangePasswordModal from "../../../Components/ChangePasswordModal/ChangePasswordModal";
import api from "../../../api";
import { loginUser, logoutUser } from "../../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import ConfirmationToast from "../../../Components/Toasts/ConfirmationToast/ConfirmationToast";
import { validateFileSize, validateFileType } from "../../../Utils/Functions";


const ProSettingsPage = () => {
    const user = useSelector((state: any) => state.user?.user);
    const reduxDispatch = useDispatch();
    const { t } = useTranslation();
    const [newUserAccount, setNewUserAccount] = useState<any>({ username: user?.username, email: user?.email });
    const [newUserBrand, setNewUserBrand] = useState<any>({ website: user?.userParams?.website, proEmail: user?.userParams?.proEmail, color: user?.userParams?.color, brandName: user?.userParams?.brandName });
    const [newLogo, setNewLogo] = useState<any>(user?.userParams?.logo);
    const uploadLogoRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [validEmail, setValidEmail] = useState(true);
    const [validUsername, setValidUsername] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState("settings.account.needEmail");


    useEffect(() => {
        async function getUserAsync() {
            setLoading(true);
            try {
                api.get("api/user/getUser/").then(res => {
                    reduxDispatch(loginUser(res.data));
                    setLoading(false);
                }).catch(e => {
                    reduxDispatch(logoutUser());
                    // If no gallery, navigate back to root
                    navigate(`/pro/login`, { replace: true });
                });
            } catch (e) {
                reduxDispatch(logoutUser());
                console.debug(e);
                // If no gallery, navigate back to root
                navigate(`/pro/login`, { replace: true });
            }
        }
        getUserAsync();
        setNewUserAccount({ username: user?.username, email: user?.email });

    }, []);

    useEffect(() => {
        setNewLogo(user?.userParams?.logo);
        setNewUserAccount({ username: user?.username, email: user?.email });
        setNewUserBrand({ website: (user?.userParams?.website?.match(/^https?:\/\/(.*)/) ? user?.userParams?.website?.match(/^https?:\/\/(.*)/)[1] : user?.userParams?.website), proEmail: user?.userParams?.proEmail, color: user?.userParams?.color, brandName: user?.userParams?.brandName });
    }, [user]);

    function saveChangesAccount() {
        if (!newUserAccount?.username || !newUserAccount?.email) {
            setValidEmail(!!newUserAccount?.email);
            setValidUsername(!!newUserAccount?.username);
            setEmailErrorMessage("settings.account.needEmail");
            return;
        }
        if (!newUserAccount?.email.match(/^\S+@\S+\.\S+$/)) {
            setValidEmail(false);
            setValidUsername(!!newUserAccount?.username);
            setEmailErrorMessage("settings.account.needValidEmail");
            return;
        }
        api.put("api/user/updateUser", { username: newUserAccount?.username, email: newUserAccount?.email }).then(res => {
            reduxDispatch(loginUser(res.data));
            setValidEmail(true);
            setValidUsername(true);
            openConfirmationToast("account");
        }).catch(e => {
            if (String(e).includes("400")) {
                setValidUsername(!!newUserAccount?.username);
                setValidEmail(false);
                setEmailErrorMessage("settings.account.takenEmail");
            } else {
                openConfirmationToast("account.error", true);
            }
        });
    }

    function saveChangesBrand() {
        let website = newUserBrand.website;
        if (!newUserBrand?.website?.match(/^(https?:\/\/)/)) {
            website = ["https://", newUserBrand.website].join("");
        }
        api.put("api/user/updateUserLogo", { logo: newLogo }).then(res => {
            reduxDispatch(loginUser(res.data));
            openConfirmationToast("brandIdentity");
            api.put("api/user/updateUserParams", { color: newUserBrand?.color, brandName: newUserBrand?.brandName, proEmail: newUserBrand?.proEmail, website: website }).then(res => {
                reduxDispatch(loginUser(res.data));
                openConfirmationToast("brandIdentity");

            }).catch(e => {
                console.debug(e);
                openConfirmationToast("brandIdentity.error", true);
            });
        }).catch(e => {
            console.debug(e);
            openConfirmationToast("brandIdentity.error", true);
        });

    }

    function handleChangeAccount(data: any, field: string) {
        const newAccountCopy = Object.assign({}, newUserAccount);
        newAccountCopy[field] = data;
        setNewUserAccount(newAccountCopy);
    }

    function handleChangeBrand(data: any, field: string) {
        const newBrandCopy = Object.assign({}, newUserBrand);
        newBrandCopy[field] = data;
        setNewUserBrand(newBrandCopy);
    }

    function addImage() {
        const file = uploadLogoRef?.current?.files ? uploadLogoRef.current.files[0] : null;
        try {
            if (file) {
                if (!validateFileType(file.type)) {
                    throw new Error("fileType.error");
                }
                if (!validateFileSize(file.size)) {
                    throw new Error("fileSize.error");
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener(
                    "load",
                    () => {
                        setNewLogo(reader.result);
                    },
                    false,
                );
            } else {
                setNewLogo(user?.userParams?.logo);
            }
        } catch (e: any) {
            console.debug(e.message);
            openConfirmationToast(e.message, true);
        }

    }

    function delImage() {
        setNewLogo(null);
    }

    /**
* Handle the change password modal
*/
    const [showChangePasswordModal, setChangePasswordModal] = useState(false);

    /**
     * Close the change password modal
     */
    function closeChangePasswordModal() {
        setChangePasswordModal(false);
    }

    /**
 * Open the change password modal to send a share email to the customer
 */
    function openChangePasswordModal() {
        setChangePasswordModal(true);
    }

    /**
* Manage the confirmation toast
*/
    const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

    /**
     * Close the confirmation toast
     */
    function closeConfirmationToast() {
        const newToast = Object.assign({}, confirmationToastManager);
        newToast.open = false;
        setChangeConfirmationToastManager(newToast);
    }

    /**
    * Pop a confirmation toast
    */
    function openConfirmationToast(type: string, error = false) {
        setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
    }


    return <>
        <div className="main-content-pro navbar-margin-top">
            <ChangePasswordModal handleClose={closeChangePasswordModal} isOpen={showChangePasswordModal} toastManager={openConfirmationToast}></ChangePasswordModal>
            <ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>

            {loading && <Spinner animation="border" />}
            {!loading &&
                <div className="user-settings">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="account">
                        <Row>
                            <Col md={3} className="menu-col">
                                <h1>{t("settings.title")}</h1>
                                <Nav variant="pills" className="flex-column user-settings-menu">
                                    <Nav.Item>
                                        <Nav.Link eventKey="account">{t("settings.account.title")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="brand">{t("settings.brandIdentity.title")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>

                            <Col md={9} className="user-settings-content">
                                <Tab.Content>

                                    <Tab.Pane eventKey="account">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="inputUsername">{t("settings.account.username")} <span className="required">({t("general.required")})</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="inputUsername"
                                                required
                                                onChange={(e) => handleChangeAccount(e.target.value, "username")}
                                                aria-describedby="usernameHelpBlock"
                                                placeholder={t("settings.account.enterUsername")}
                                                defaultValue={user?.username}
                                                isInvalid={!validUsername}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t("settings.account.needUsername")}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="inputEmail">{t("settings.account.email")} <span className="required">({t("general.required")})</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                id="inputEmail"
                                                required
                                                onChange={(e) => handleChangeAccount(e.target.value, "email")}
                                                aria-describedby="emailHelpBlock"
                                                placeholder={t("settings.account.enterEmail")}
                                                defaultValue={user?.email}
                                                isInvalid={!validEmail}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t(emailErrorMessage)}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button variant="primary" onClick={saveChangesAccount} className="form-group">
                                            {t("general.save")}
                                        </Button>

                                        <Form.Group className="form-group">
                                            <Form.Label>{t("general.password")}</Form.Label>

                                            <Button variant="outline-primary" onClick={openChangePasswordModal} className="form-block-btn">
                                                {t("settings.changePassword.field")}
                                            </Button>
                                        </Form.Group>

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="brand">

                                        <Form.Group className="form-group">
                                            <Form.Label>{t("settings.brandIdentity.logo")}</Form.Label>
                                            {newLogo && <div className="logo-wrapper">
                                                <img src={newLogo} className="pointer" onClick={() => { uploadLogoRef.current?.click(); }}></img>
                                                <Button onClick={() => delImage()} variant="outline-primary" className="icon-button"><i className="bi bi-button bi-trash"></i></Button>
                                                <input type="file" id="file1" accept="image/png, image/jpeg" ref={uploadLogoRef} style={{ display: "none" }} onChange={() => addImage()} />
                                            </div>}
                                            {!newLogo &&
                                                <><Button variant="outline-primary" onClick={() => { uploadLogoRef.current?.click(); }} className="form-block-btn">
                                                    {t("settings.brandIdentity.addLogo")}
                                                </Button><input type="file" id="file2" accept="image/png, image/jpeg" ref={uploadLogoRef} style={{ display: "none" }} onChange={() => addImage()} /></>
                                            }
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="colorPicker">{t("settings.brandIdentity.color")}</Form.Label>
                                            <Form.Control
                                                type="color"
                                                id="colorPicker"
                                                aria-describedby="colorHelpBlock"
                                                onChange={(e) => handleChangeBrand(e.target.value, "color")}
                                                placeholder={t("settings.brandIdentity.enterColor")}
                                                defaultValue={user?.userParams?.color}
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="inputBrandName">{t("settings.brandIdentity.brandName")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="inputBrandName"
                                                aria-describedby="brandNameHelpBlock"
                                                onChange={(e) => handleChangeBrand(e.target.value, "brandName")}
                                                placeholder={t("settings.brandIdentity.enterBrandName")}
                                                defaultValue={user?.userParams?.brandName}
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="inputWebsite">{t("settings.brandIdentity.website")}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon3">
                                                    https://
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    id="inputWebsite"
                                                    aria-describedby="websiteHelpBlock"
                                                    onChange={(e) => handleChangeBrand(e.target.value, "website")}
                                                    placeholder={t("settings.brandIdentity.enterWebsite")}
                                                    defaultValue={newUserBrand?.website}

                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Button variant="primary" onClick={saveChangesBrand}>
                                            {t("general.save")}
                                        </Button>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            }
        </div>
    </>;
};

export default ProSettingsPage;

function updateUser(arg0: { key: string; data: any; }): any {
    throw new Error("Function not implemented.");
}
