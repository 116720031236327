import * as actionTypes from '../consts/utilsConsts';

export const utilsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case actionTypes.FAVS_ON:
            return {
                ...state,
                favsStatus: true
            };
        case actionTypes.FAVS_OFF:
            return {
                ...state,
                favsStatus: false
            };
        default:
            return state;
    }
};