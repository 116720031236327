import * as actionTypes from '../consts/userConsts';
import update from 'immutability-helper';


export const userReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case actionTypes.LOGIN_USER:
            return {
                ...state,
                user: action.payload,
            };
        case actionTypes.USER_GALLERIES:
            return {
                ...state,
                galleries: action.payload,
            };
        case actionTypes.SET_USER_GALLERY:
            return {
                ...state,
                activeGallery: action.payload,
            };
        case actionTypes.UPDATE_GALLERY_STATUS:
            return update(state, {
                galleries: {
                    [state.galleries.indexOf(state.galleries.find((x: any) => x._id === action.payload?._id))]: {
                        status: { $set: action.payload?.status }
                    }
                }
            });
        case actionTypes.UPDATE_ACTIVE_GALLERY:
            return update(state, {
                activeGallery: {

                    [action.payload.key]: { $set: action.payload?.data }

                }
            });
        case actionTypes.UPDATE_USER:
            return update(state, {
                user: {

                    [action.payload.key]: { $set: action.payload?.data }

                }
            });
        case actionTypes.UPDATE_ACTIVE_GALLERY_IMAGES:
            if (action.payload.action === "add") {
                return update(state, {
                    activeGallery: {

                        imgs: { $push: action.payload?.data }

                    }
                });
            } else if (action.payload.action === "delete") {
                return update(state, {
                    activeGallery: {

                        imgs: { $splice: [[state.activeGallery?.imgs?.indexOf(state.activeGallery?.imgs?.find((x: any) => x._id === action.payload?.data)), 1]] }
                    }
                });
            } else if (action.payload.action === "update") {
                return update(state, {
                    activeGallery: {
                        imgs: {
                            [state.activeGallery?.imgs?.indexOf(state.activeGallery?.imgs?.find((x: any) => x._id === action.payload?.data?._id))]: { $set: action.payload?.data }
                        },
                        favourites: {
                            [state.activeGallery?.favourites?.indexOf(state.activeGallery?.favourites?.find((x: any) => x._id === action.payload?.data?._id))]: { $set: action.payload?.data }
                        }

                    }
                });
            }
            break;
        case actionTypes.UPDATE_GALLERY_LIST:
            return update(state, {
                galleries: { $push: [action.payload] }
            });
        case actionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
};
