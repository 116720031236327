
import { Button, Card } from 'react-bootstrap';
import './GalleryCard.scss';
import { gallery, galleryStatusSelector } from '../../Utils/types';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import { logoutUser, updateGalleryStatus } from '../../redux/actions/userActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import ShareGalleryModal from '../ShareGalleryModal/ShareGalleryModal';
import ConfirmAlert from '../ConfirmAlert/ConfirmAlert';


function GalleryCard(props: { gallery?: gallery, toastManager?: any }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const reduxDispatch = useDispatch();

    /**
     * Publish a draft or an archived gallery
     */
    function publishGallery() {
        api.put("api/galleryAdmin/publishGallery/" + props?.gallery?._id).then(res => {
            reduxDispatch(updateGalleryStatus(res.data));
        }).catch(e => {
            props?.toastManager("publishGallery.error", true);
        });
    }

    function goToGallery(id: string | undefined) {
        navigate(`/pro/gallery/${id}`);
    }

    /**
* Handle the share gallery modal
*/
    const [showShareGalleryModal, setShareGalleryModal] = useState(false);

    /**
     * Close the share gallery modal
     */
    function closeShareGalleryModal() {
        setShareGalleryModal(false);
    }

    /**
 * Open the share gallery modal to send a share email to the customer
 */
    function openShareGalleryModal() {
        setShareGalleryModal(true);
    }



    return <>
        <ShareGalleryModal handleClose={closeShareGalleryModal} isOpen={showShareGalleryModal} toastManager={props?.toastManager} galleryID={props?.gallery?._id}></ShareGalleryModal>

        <Card className="gallery-card card-shadow">
            <div className="card-img-wrapper pointer" onClick={() => goToGallery(props?.gallery?._id)}>
                {(props?.gallery?.status === galleryStatusSelector.DRAFT || props?.gallery?.status === galleryStatusSelector.ARCHIVED) && <div className="status-label">{t("galleryStatus." + props?.gallery?.status)}</div>}
                <Card.Img variant="top" src={props?.gallery?.cover?.url ? props.gallery.cover.url : "/img/headersample.png"} />
            </div>
            <Card.Body>
                <Card.Title className="pointer" onClick={() => goToGallery(props?.gallery?._id)}>{props?.gallery?.name}
                </Card.Title>
                {props?.gallery?.status === galleryStatusSelector.PUBLISHED &&
                    <Button onClick={openShareGalleryModal} variant="outline-primary">{t("galleryStatus.statusAction.share")}</Button>}
                {(props?.gallery?.status === galleryStatusSelector.DRAFT || props?.gallery?.status === galleryStatusSelector.ARCHIVED) &&
                    <Button onClick={publishGallery} variant="outline-primary">{t("galleryStatus.statusAction.publish")}</Button>}

            </Card.Body>
        </Card>
    </>;
}

export default GalleryCard;

