import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import './ConfirmAlert.scss';

function ConfirmAlert(props: { isOpen: boolean, handleClose: any, handleConfirm: any, text: any, dontShowID?: string }) {
    const [show, setShow] = useState(Boolean);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    /**
     * If a don't show again id was provided, update the session storage with the id of the popup nt be shown again before triggering the onfirmation fuction
     */
    function handlePreConfirm() {
        if (dontShowAgain && props?.dontShowID) {
            window.sessionStorage.setItem(props?.dontShowID, "true");
        } else if (!dontShowAgain && props?.dontShowID) {
            window.sessionStorage.removeItem(props?.dontShowID);
        }
        props?.handleConfirm();
        props?.handleClose();
    }

    function handleChange(data: boolean) {
        setDontShowAgain(data);
    }

    return (
        <>
            <Modal show={show} onHide={props.handleClose} className="top-alert confirm-alert">
                <Modal.Body>
                    {t(props?.text)}
                    {
                        props?.dontShowID &&
                        <Form.Check
                            type="checkbox"
                            id={"dontShow-" + props?.dontShowID}
                        >
                            <Form.Check.Input type="checkbox"
                                onChange={(e) => handleChange(e.target.checked)}
                            />
                            <Form.Check.Label>
                                {t("general.dontShowPopupAgain")}
                            </Form.Check.Label>
                        </Form.Check>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={props?.handleClose}>
                        {t("general.cancel")}
                    </Button>
                    <Button variant="primary" onClick={props?.dontShowID ? handlePreConfirm : props?.handleConfirm}>
                        {t("general.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmAlert;