import { useEffect, useRef, useState } from "react";
import "./ProGallery.scss";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../api";
import { loginUser, logoutUser, setUserGallery, updateActiveGallery, updateActiveGalleryImages } from "../../../redux/actions/userActions";
import { Container, Row, Col, Button, Tab, Nav, Form, Dropdown, InputGroup, Spinner } from "react-bootstrap";
import CardsCaroussel from "../../../Components/CardsCaroussel/CardsCaroussel";
import HelpTooltip from "../../../Components/HelpTooltip/HelpTooltip";
import { galleryStatusSelector } from "../../../Utils/types";
import ShareGalleryModal from "../../../Components/ShareGalleryModal/ShareGalleryModal";
import { validateFileType, validateFileSize } from "../../../Utils/Functions";
import ConfirmationToast from "../../../Components/Toasts/ConfirmationToast/ConfirmationToast";
import { APP_DOMAIN, TITLE_LENGTH } from "../../../config/config";
import ConfirmAlert from "../../../Components/ConfirmAlert/ConfirmAlert";
import { isMobile } from "react-device-detect";

const ProGalleryPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const galleryPro = useSelector((state: any) => state.user?.activeGallery);
    const reduxDispatch = useDispatch();
    const [editMode, setEditMode] = useState<any>({ name: false, description: false, maxFavourites: false, password: false });
    const [newGal, setNewGal] = useState<any>({ name: "", description: "", maxFavourites: "", password: "" });
    const uploadRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [loadingImgs, setLoadingImgs] = useState(false);


    useEffect(() => {
        async function getGalleryAsync() {
            setLoading(true);
            api.get("api/galleryAdmin/getGalleryAdmin/" + params.id).then(res => {
                reduxDispatch(setUserGallery(res.data));
                hidePassword();
                setLoading(false);
            }).catch(e => {
                reduxDispatch(logoutUser());
                // If no gallery, navigate back to root
                navigate(`/pro/login`, { replace: true });
            });
        }
        async function getUserAsync() {
            api.get("api/user/getUser/").then(res => {
                reduxDispatch(loginUser(res.data));
            }).catch(e => {
                reduxDispatch(logoutUser());
                // If no user, navigate back to root
                navigate(`/pro/login`, { replace: true });
            });
        }
        getGalleryAsync();
        getUserAsync();
    }, []);

    useEffect(() => {
        setNewGal({ name: galleryPro?.name, description: galleryPro?.description, maxFavourites: galleryPro?.params?.maxFavourites, password: galleryPro?.password });
    }, [galleryPro]);

    function hidePassword() {
        const doc = document.getElementById("gal-password");
        if (doc?.textContent) {
            doc.textContent = doc.textContent.replaceAll(/./gi, "*");
        }
    }

    function showPassword() {
        const doc = document.getElementById("gal-password");
        if (doc?.textContent) {
            doc.textContent = galleryPro?.password;
        }
    }

    function back() {
        navigate(`/pro/home`);
    }

    /**
* Delete a gallery
*/
    function deleteGallery() {
        api.delete("api/galleryAdmin/deleteGallery/" + galleryPro?._id).then(res => {
            reduxDispatch(setUserGallery({}));
            navigate(`/pro/home`, { replace: true });
        }).catch(e => {
            openConfirmationToast("deleteGallery.error", true);
        });
    }

    /**
     * Publish a draft or an archived gallery
     */
    function publishGallery() {
        api.put("api/galleryAdmin/publishGallery/" + galleryPro?._id).then(res => {
            reduxDispatch(updateActiveGallery({ key: "status", data: res.data.status }));
        }).catch(e => {
            openConfirmationToast("publishGallery.error", true);
        });
    }

    /**
 * Archive a gallery 
 */
    function archiveGallery() {
        api.put("api/galleryAdmin/archiveGallery/" + galleryPro?._id).then(res => {
            reduxDispatch(updateActiveGallery({ key: "status", data: res.data.status }));
        }).catch(e => {
            openConfirmationToast("archiveGallery.error", true);
        });
    }

    /**
     * Open edit mode for certain text fields
     * @param field field to be modified. 'name', 'description' or 'maxFavourites'
     */
    function openEditMode(field: string) {
        const newEditState = Object.assign({}, editMode);
        newEditState[field] = true;
        setEditMode(newEditState);
    }

    /**
     * Call the api to modify the text fields and close edit mode
     * @param field field to be modified. 'name', 'description' or 'maxFavourites'
     */
    function updateGallery(field: string) {
        if (field === "description" || field === "name") {
            const capField = field.charAt(0).toUpperCase() + field.slice(1);
            api.put(`api/galleryAdmin/editGallery${capField}/${galleryPro?._id}`, { name: newGal.name, description: newGal.description, password: newGal.password }).then(res => {
                reduxDispatch(updateActiveGallery({ key: field, data: res.data[field] }));
            }).catch(e => {
                openConfirmationToast("updateGallery.error", true);
            });
        } else if (field === "password") {
            api.put(`api/galleryAdmin/editGalleryPassword/${galleryPro?._id}`, { password: newGal.password }).then(res => {
                reduxDispatch(updateActiveGallery({ key: "password", data: res.data }));
                hidePassword();
            }).catch(e => {
                openConfirmationToast("updateGallery.error", true);

            });
        } else if (field === "maxFavourites") {
            if (maxFavRequired && (!newGal.maxFavourites || Number(newGal.maxFavourites) < 1)) {
                // setMaxFavRequired(true);
                setValidMaxNumber(false);
                setMaxFavsError("createGallery.needMaxFavs");
                return;
            } else if (Number(newGal.maxFavourites) < 0) {
                setValidMaxNumber(false);
                setMaxFavsError("createGallery.needValidNumber");
                return;
            } else {
                setValidMaxNumber(true);
                api.put("api/galleryAdmin/editGalleryParams/" + galleryPro?._id, { maxFavourites: newGal.maxFavourites }).then(res => {
                    reduxDispatch(updateActiveGallery({ key: "params", data: res.data.params }));
                    if (maxFavRequired && newGal.maxFavourites) {
                        setMaxFavRequired(false);
                    }
                }).catch(e => {
                    console.debug(e);
                    openConfirmationToast("updateGallery.error", true);
                });
            }
        }
        const newEditState = Object.assign({}, editMode);
        newEditState[field] = false;
        setEditMode(newEditState);
    }

    const [maxFavRequired, setMaxFavRequired] = useState<boolean>(false);
    const [validMaxNumber, setValidMaxNumber] = useState<boolean>(true);
    const [maxFavsError, setMaxFavsError] = useState<string>("createGallery.needMaxFavs");


    /**
     * Modify the boolean params
     * @param data The new value
     * @param field The param to be modified
     */
    function updateGalleryParams(data: any, field: string, e?: any) {
        if ((field === "hardMaxFavourites" && editMode.maxFavourites && (!newGal?.maxFavourites || Number(newGal?.maxFavourites) < 1)) ||
            (field === "hardMaxFavourites" && data === true && (!newGal?.maxFavourites || Number(newGal?.maxFavourites) < 1))) {
            e.preventDefault();
            e.stopPropagation();
            openEditMode('maxFavourites');
            setMaxFavRequired(true);
            setValidMaxNumber(false);
            setMaxFavsError("createGallery.needMaxFavs");
            return;
        } else {
            setMaxFavRequired(false);
            setValidMaxNumber(true);
            const newParams = Object.assign({}, galleryPro?.params);
            newParams[field] = data;
            api.put("api/galleryAdmin/editGalleryParams/" + galleryPro?._id, { sendFavs: newParams.sendFavs, watermark: newParams.watermark, download: newParams.download, hardMaxFavourites: newParams.hardMaxFavourites, isLocked: newParams.isLocked }).then(res => {
                reduxDispatch(updateActiveGallery({ key: "params", data: res.data.params }));
            }).catch(e => {
                openConfirmationToast("updateGallery.error", true);
            });
        }
    }


    /**
     * Update the temp gallery object with new values 
     * @param data New value of the field
     * @param field field field to be modified. 'name', 'description' or 'maxFavourites'
     */
    function handleNewGalChange(data: any, field: string) {
        const newGalCopy = Object.assign({}, newGal);
        newGalCopy[field] = data;
        setNewGal(newGalCopy);
        if (field === "maxFavourites" && (!data || Number(data) < 1) && galleryPro?.params?.hardMaxFavourites) {
            setMaxFavRequired(true);
        }
    }

    /**
     * Upload images
     * 
     */
    function uploadImages() {
        const files = uploadRef?.current?.files ? Array.from(uploadRef.current.files) : null;
        try {
            if (files) {
                for (const file of files) {
                    setLoadingImgs(true);
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.addEventListener(
                        "load",
                        () => {
                            try {
                                if (!validateFileType(file.type)) {
                                    throw new Error("fileType.error");
                                }
                                if (!validateFileSize(file.size)) {
                                    throw new Error("fileSize.error");
                                }
                                // convert image file to base64 string
                                const filename = file.name.substring(0, file.name.lastIndexOf('.'));
                                api.post("api/galleryAdmin/addImages/" + galleryPro?._id, { imageObjs: [{ url: reader.result, name: filename }] }).then(res => {
                                    reduxDispatch(updateActiveGalleryImages({ action: "add", data: res.data }));
                                    setLoadingImgs(false);
                                }).catch(e => {
                                    console.debug(e);
                                    setLoadingImgs(false);
                                    openConfirmationToast("imageUpload.error", true);
                                });
                            } catch (e: any) {
                                console.debug(e);
                                setLoadingImgs(false);
                                openConfirmationToast(e.message, true);
                            }
                        },
                        false,
                    );
                }

            }
        } catch (e: any) {
            console.debug(e);
            openConfirmationToast("genericError", true);
        }

    }

    /**
* Manage the confirmation toast
*/
    const [confirmationToastManager, setChangeConfirmationToastManager] = useState({ open: false, head: '', body: '', error: false });

    /**
     * Close the confirmation toast
     */
    function closeConfirmationToast() {
        const newToast = Object.assign({}, confirmationToastManager);
        newToast.open = false;
        setChangeConfirmationToastManager(newToast);
    }

    /**
    * Pop a confirmation toast
    */
    function openConfirmationToast(type: string, error = false) {
        setChangeConfirmationToastManager({ open: true, head: 'feedback.' + type + '.head', body: 'feedback.' + type + '.body', error: error });
    }


    /**
 * Handle the share gallery modal
 */
    const [showShareGalleryModal, setShareGalleryModal] = useState(false);

    /**
     * Close the share gallery modal
     */
    function closeShareGalleryModal() {
        setShareGalleryModal(false);
    }

    /**
 * Open the share gallery modal to send a share email to the customer
 */
    function openShareGalleryModal() {
        setShareGalleryModal(true);
    }

    /**
* Handle the confirm alert
*/
    const [showConfirmAlert, setShowConfimAlert] = useState(false);

    /**
     * Close the confirm alert
     */
    function closeConfirmAlert() {
        setShowConfimAlert(false);
    }

    /**
 * Open the confirm alert
 */
    function openConfirmAlert() {
        setShowConfimAlert(true);
    }



    return <>
        <div className="main-content-pro user-gal navbar-margin-top gallery-form-styles">
            <ConfirmAlert handleClose={closeConfirmAlert} handleConfirm={deleteGallery} isOpen={showConfirmAlert} text={"userGallery.confirmDeleteGallery"}></ConfirmAlert>

            <ShareGalleryModal handleClose={closeShareGalleryModal} isOpen={showShareGalleryModal} toastManager={openConfirmationToast} galleryID={galleryPro?._id}></ShareGalleryModal>
            <ConfirmationToast isOpen={confirmationToastManager?.open} handleClose={closeConfirmationToast} head={confirmationToastManager.head} body={confirmationToastManager.body} error={confirmationToastManager.error}></ConfirmationToast>
            <Container fluid>
                {loading && <Spinner animation="border" />}
                {!loading && <Row id="user-gal-main-row">
                    <Col xs={12} md={4} xl={3} className="user-gal-menu user-gal-col">
                        <div className="c-row menu-elem">
                            <Button className="icon-button" onClick={() => back()}><i className="bi bi-arrow-left"></i></Button>

                            {!editMode?.name && <h2 className="text-cursor end-elem-responsive" onClick={() => openEditMode("name")}>{galleryPro?.name} <i className="bi bi-pencil small-icon"></i></h2>}
                            {editMode?.name && <Form.Group className="gal-menu-input end-elem-responsive">
                                <InputGroup>
                                    <Form.Control placeholder={t("createGallery.enterTitle")}
                                        defaultValue={newGal?.name}
                                        onChange={(e) => handleNewGalChange(e.target.value, "name")}
                                        maxLength={TITLE_LENGTH}
                                    />
                                    <Button variant="outline-primary" className="icon-button" onClick={() => updateGallery("name")}><i className="bi bi-check"></i></Button>
                                </InputGroup>

                            </Form.Group>}

                            <Dropdown align="end">
                                <Dropdown.Toggle id="dropdown-basic" className="icon-button">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {galleryPro?.status === galleryStatusSelector.PUBLISHED && <Dropdown.Item onClick={archiveGallery}>{t("galleryStatus.statusAction.archive")}</Dropdown.Item>}
                                    <Dropdown.Item onClick={openConfirmAlert}>{t("galleryStatus.statusAction.delete")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Row className="gal-specs">
                            <Col xs={12} sm={6} md={12} className="menu-elem">
                                <div className="card-img-wrapper menu-elem">
                                    <div className="status-label">{t("galleryStatus." + galleryPro?.status)}</div>
                                    <img className={galleryPro?.cover?._id ? "" : "cover-back"} src={galleryPro?.cover?._id ? galleryPro.cover.url : "/img/headersample.png"} />
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={12} className="menu-elem" >
                                {!editMode?.description && <p className={galleryPro?.description ? 'text-cursor' : 'text-cursor italic'} onClick={() => openEditMode("description")}>{galleryPro?.description ? galleryPro?.description : t("placeholders.noDescription")} <i className="bi bi-pencil small-icon"></i></p>}
                                {editMode?.description && <Form.Group className="gal-menu-input form-group">
                                    <InputGroup>
                                        <Form.Control as="textarea" maxLength={300} placeholder={t("createGallery.enterDescription")}
                                            defaultValue={newGal?.description}
                                            onChange={(e) => handleNewGalChange(e.target.value, "description")}
                                        />
                                        <Button className="icon-button" onClick={() => updateGallery("description")}><i className="bi bi-check"></i></Button>
                                    </InputGroup>
                                </Form.Group>}
                                <div className="c-row">
                                    <p className="end-elem-responsive">{t("general.password")}</p>
                                    {!editMode?.password && galleryPro?.password?.length &&
                                        <p className="text-cursor" onMouseEnter={() => showPassword()} onMouseLeave={() => hidePassword()} onClick={() => openEditMode("password")}><span id="gal-password">{galleryPro?.password}</span> <i className="bi bi-pencil small-icon"></i></p>
                                    }
                                    {!editMode?.password && !galleryPro?.password?.length &&
                                        <p className="text-cursor italic" onClick={() => openEditMode("password")}>{t("placeholders.noPassword")} <i className="bi bi-pencil small-icon"></i></p>
                                    }
                                    {editMode?.password && <Form.Group className="gal-menu-input form-group">
                                        <InputGroup>
                                            <Form.Control placeholder={t("createGallery.enterPassword")}
                                                defaultValue={newGal?.password}
                                                onChange={(e) => handleNewGalChange(e.target.value, "password")}
                                            />
                                            <Button className="icon-button" onClick={() => updateGallery("password")}><i className="bi bi-check"></i></Button>
                                        </InputGroup>
                                    </Form.Group>}
                                </div>
                                {galleryPro?.password?.length &&
                                    <Form.Check
                                        type="switch"
                                        id="sendFavs"
                                    >
                                        <Form.Check.Input
                                            id="send-favs-input"
                                            defaultChecked={galleryPro?.params?.sendFavs}
                                            onChange={(e) => updateGalleryParams(e.target.checked, "sendFavs")}
                                        />
                                        <Form.Check.Label className="label-with-help">
                                            {t("createGallery.sendFavourites")}
                                            <HelpTooltip text={t("userGallery.help.sendFavs")}></HelpTooltip>
                                        </Form.Check.Label>
                                    </Form.Check>
                                }

                                <Form.Check
                                    type="switch"
                                    id="download"
                                >
                                    <Form.Check.Input
                                        id="download-input"
                                        defaultChecked={galleryPro?.params?.download}
                                        onChange={(e) => updateGalleryParams(e.target.checked, "download")}
                                    />
                                    <Form.Check.Label>
                                        {t("createGallery.allowDownloads")}
                                    </Form.Check.Label>
                                </Form.Check>

                                <Form.Check
                                    type="switch"
                                    id="watermark"
                                >
                                    <Form.Check.Input
                                        id="watermark-input"
                                        defaultChecked={galleryPro?.params?.watermark}
                                        onClick={(e) => { const ev = e as any; updateGalleryParams(ev.target?.checked, "watermark", e); }}
                                    />
                                    <Form.Check.Label>
                                        {t("createGallery.applyWatermark")}
                                        <HelpTooltip text={t("userGallery.help.watermark")}></HelpTooltip>
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check
                                    type="switch"
                                    id="hardMaxFavourites"
                                >
                                    <Form.Check.Input
                                        id="hard-max-input"
                                        defaultChecked={galleryPro?.params?.hardMaxFavourites}
                                        onClick={(e) => { const ev = e as any; updateGalleryParams(ev.target?.checked, "hardMaxFavourites", e); }}
                                    />
                                    <Form.Check.Label className="label-with-help">
                                        {t("createGallery.allowHardMax")}
                                        <HelpTooltip text={t("userGallery.help.hardMaxFavs")}></HelpTooltip>
                                    </Form.Check.Label>
                                </Form.Check>
                                {!editMode?.maxFavourites &&
                                    <Form.Group className="form-group">
                                        <Row >

                                            <Form.Label column xs={10} className="label-with-help text-cursor">
                                                {t("createGallery.maxFavourites")}
                                                <HelpTooltip text={t("userGallery.help.maxFavs")} alignment={isMobile ? "left" : ''}></HelpTooltip>
                                            </Form.Label>
                                            <Col xs={2} onClick={() => openEditMode("maxFavourites")}>
                                                <p className="text-cursor">{galleryPro?.params?.maxFavourites + " "}
                                                    <i className="bi bi-pencil small-icon"></i>
                                                </p>
                                            </Col>

                                        </Row>
                                    </Form.Group>}
                                {editMode?.maxFavourites && <Form.Group className="form-group" as={Row}>
                                    <Form.Label column xs={8} className="label-with-help">
                                        {t("createGallery.maxFavourites")}
                                        {maxFavRequired && <span className="required">({t("general.required")})</span>}
                                        <HelpTooltip text={t("userGallery.help.maxFavs")} alignment={isMobile ? "left" : ''}></HelpTooltip>

                                    </Form.Label>
                                    <Col xs={4} className="gal-menu-input maxFavs-wrapper">
                                        <InputGroup>
                                            <Form.Control type="number" min="0" placeholder="0" defaultValue={galleryPro?.params?.maxFavourites}
                                                onChange={(e) => handleNewGalChange(e.target.value, "maxFavourites")} isInvalid={!validMaxNumber}
                                            />
                                            <Button className="icon-button" onClick={() => updateGallery("maxFavourites")}><i className="bi bi-check"></i></Button>
                                            <Form.Control.Feedback type="invalid">
                                                {t(maxFavsError ? maxFavsError : "createGallery.needMaxFavs")}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>

                                </Form.Group>}
                                <Form.Check
                                    type="switch"
                                    id="blockFav"
                                >
                                    <Form.Check.Input
                                        id="bloack-favs-input"
                                        defaultChecked={galleryPro?.params?.isLocked}
                                        onChange={(e) => updateGalleryParams(e.target.checked, "isLocked")}
                                    />
                                    <Form.Check.Label className="label-with-help">
                                        {t("createGallery.blockFavourites")}
                                        <HelpTooltip text={t("userGallery.help.blockFavs")}></HelpTooltip>
                                    </Form.Check.Label>
                                </Form.Check>
                            </Col>
                        </Row>
                        {galleryPro?.status === galleryStatusSelector.PUBLISHED && <a href={`${APP_DOMAIN}/${galleryPro?._id}`} target="_blank" rel="noreferrer">{`${APP_DOMAIN}/${galleryPro?._id}`}</a>}
                        {galleryPro?.status === galleryStatusSelector.PUBLISHED &&
                            <Button onClick={openShareGalleryModal} variant="outline-primary" className="menu-elem">{t("galleryStatus.statusAction.share")}</Button>}
                        {(galleryPro?.status === galleryStatusSelector.DRAFT || galleryPro?.status === galleryStatusSelector.ARCHIVED) &&
                            <Button onClick={publishGallery} variant="outline-primary" className="menu-elem">{t("galleryStatus.statusAction.publish")}</Button>}
                    </Col>

                    <Col xs={12} md={8} xl={9} className="user-gal-col">
                        <Tab.Container defaultActiveKey="first">

                            <div className="c-row">
                                <Nav variant="tabs" className="end-elem-responsive">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">{t("userGallery.images")} ({galleryPro?.imgs?.length})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">{t("userGallery.favourites")}  ({galleryPro?.favourites?.length})</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Button onClick={() => { uploadRef.current?.click(); }}>{isMobile ? <i className='bi bi-plus'></i> : t("userGallery.addImage")}</Button>
                                <input type="file" multiple={true} accept="image/png, image/jpeg" id="file" ref={uploadRef} style={{ display: "none" }} onChange={() => uploadImages()} />
                            </div>

                            <Tab.Content>

                                <Tab.Pane eventKey="first">
                                    <CardsCaroussel userGal={true} cardsList={galleryPro?.imgs} loading={loadingImgs} ></CardsCaroussel>
                                    {!galleryPro?.imgs?.length && !loading && <p>{t('placeholders.noPhotosYet')}</p>}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <CardsCaroussel userGal={true} cardsList={galleryPro?.favourites} ></CardsCaroussel>
                                    {!galleryPro?.favourites?.length && !loading && <p>{t('placeholders.noFavsYetAdmin')}</p>}
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </Col>
                </Row>}
            </Container>
        </div>

    </>;

};

export default ProGalleryPage;