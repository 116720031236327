
import "./App.scss";
import ClientHomePage from "./Pages/Client/ClientHome/ClientHome";
import ProLoginPage from "./Pages/Pro/ProLogin/ProLogin";
import ProHomePage from "./Pages/Pro/ProHome/ProHome";
import ProGalleryPage from "./Pages/Pro/ProGallery/ProGallery";
import ProSettingsPage from "./Pages/Pro/ProSettings/ProSettings";
import FooterComponent from "./Components/Footer/Footer";
import NavBarComponent from "./Components/NavBar/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ClientGalleryPage from "./Pages/Client/ClientGallery/ClientGallery";
import ProtectedUserRoutesComponent from "./Components/ProtectedUserRoutes";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { swapTheme } from "./Utils/Functions";
import GeneralHomePage from "./Pages/GeneralHome/GeneralHome";

function App() {
	document.addEventListener("contextmenu", function (e) {
		const targetT = e.target as HTMLButtonElement;
		if (targetT?.nodeName === "IMG") {
			e.preventDefault();
		}
	}, false);
	//<Route path="gallery/:id" element={<ProtectedGalleryRoutesComponent />}>
	const gallery = useSelector((state: any) => state.gallery?.gallery);

	/**
	 * If on a gallery client-side and the gallery owner has a specific theme color, swap primary color. Otherwise, keep the default primary
	 */
	useEffect(() => {
		const og = getComputedStyle(document.body).getPropertyValue('--bs-primary');
		swapTheme(gallery?.owner?.userParams?.color ? gallery?.owner?.userParams?.color : og);
	}, [gallery]);

	return (
		<BrowserRouter>
			<NavBarComponent />
			<Routes>

				<Route path="/" element={<GeneralHomePage />} />
				<Route path="/:id" element={<ClientHomePage />} />
				<Route path="gallery" element={<ClientHomePage />} />
				<Route path="gallery/:id" element={<ClientGalleryPage />} />
				<Route path="*" element="404" />

				<Route element={<ProtectedUserRoutesComponent />}>
					<Route path="pro/login" element={<ProLoginPage />} />
					<Route path="pro/home" element={<ProHomePage />} />
					<Route path="pro/gallery/:id" element={<ProGalleryPage />} />
					<Route path="pro/settings" element={<ProSettingsPage />} />
				</Route>

			</Routes>
			<FooterComponent />
		</BrowserRouter>
	);
}

export default App;
