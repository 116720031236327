import * as actionTypes from "../consts/userConsts";

export const loginUser = (user: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.LOGIN_USER,
        payload: user
    });
};

export const getUserGalleries = (galleries: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.USER_GALLERIES,
        payload: galleries
    });
};

export const updateGalleryStatus = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_GALLERY_STATUS,
        payload: gallery
    });
};

export const updateActiveGallery = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_ACTIVE_GALLERY,
        payload: gallery
    });
};

export const updateeUser = (user: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_USER,
        payload: user
    });
};

export const updateActiveGalleryImages = (data: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_ACTIVE_GALLERY_IMAGES,
        payload: data
    });
};

export const setUserGallery = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.SET_USER_GALLERY,
        payload: gallery
    });
};

export const updateGalleryList = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_GALLERY_LIST,
        payload: gallery
    });
};

export const logoutUser = () => (dispatch: (arg0: { type: any; }) => void) => {
    dispatch({ type: actionTypes.LOGOUT_USER });
};