import { useTranslation } from "react-i18next";
import { gallery, galleryStatusSelector } from "../../Utils/types";
import GalleryCard from "../GalleryCard/GalleryCard";
import './GalleryCardsCaroussel.scss';
import { useEffect, useState } from "react";

function GalleryCardsCaroussel(props: { galleryType?: string | undefined, cardsList: gallery[] | undefined, toastManager?: any }) {
    const [displayList, setDisplayList] = useState<any[] | undefined>([]);
    const { t } = useTranslation();

    useEffect(() => {
        switch (props.galleryType) {
            case galleryStatusSelector.ALL:
                setDisplayList(props.cardsList);
                break;
            case galleryStatusSelector.PUBLISHED:
                setDisplayList(props.cardsList?.filter(gal => gal.status === galleryStatusSelector.PUBLISHED));
                break;
            case galleryStatusSelector.DRAFT:
                setDisplayList(props.cardsList?.filter(gal => gal.status === galleryStatusSelector.DRAFT));
                break;
            case galleryStatusSelector.ARCHIVED:
                setDisplayList(props.cardsList?.filter(gal => gal.status === galleryStatusSelector.ARCHIVED));
                break;
            default:
                setDisplayList(props.cardsList);
                break;
        }
    }, [props?.galleryType, props?.cardsList]);

    return <>
        <div className="flex-gallery">
            {
                displayList && displayList.map((gal: any, index: number) => (
                    <>
                        <GalleryCard gallery={gal} key={index} toastManager={props?.toastManager} />
                    </>
                ))
            }
            {!displayList?.length && <p>{t("placeholders.noGalleryPro")}</p>}
        </div>
    </>;
}

export default GalleryCardsCaroussel;