import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { userReducer } from "./reducers/userReducers";
import { galleryReducer } from './reducers/galleryReducers';
import { utilsReducer } from "./reducers/utilsReducers";


const reducer = combineReducers({
    user: userReducer,
    gallery: galleryReducer,
    utils: utilsReducer
});

const INITIAL_STATE = {
    user: { user: {}, galleries: {}, activeGallery: {} },
    gallery: { gallery: {}, hasPassword: true, fullyLoaded: false },
    utils: { favsStatus: false }
};

const middleware = [thunk];

/**
 * This function accepts the app state, and saves it to sessionStorage
 * @param state
 
const saveState = (state: any) => {
    try {
        // Convert the state to a JSON string 
        const serialisedState = JSON.stringify(state);

        // Save the serialised state to sessionStorage against the key 'app_state'
        window.sessionStorage.setItem('app_state', serialisedState);
    } catch (err) {
        // Log errors here, or ignore
    }
};
*/

/**
 * This function checks if the app state is saved in sessionStorage
 
const loadState = () => {
    try {
        // Load the data saved in sessionStorage, against the key 'app_state'
        const serialisedState = window.sessionStorage.getItem('app_state');

        // Passing undefined to createStore will result in our app getting the default state
        // If no data is saved, return undefined
        if (!serialisedState) return undefined;

        // De-serialise the saved state, and return it.
        return JSON.parse(serialisedState);
    } catch (err) {
        // Return undefined if sessionStorage is not available, 
        // or data could not be de-serialised, 
        // or there was some other error
        return undefined;
    }
};
*/

//const oldState = loadState();
const store = createStore(reducer, INITIAL_STATE, composeWithDevTools(applyMiddleware(...middleware)));

/**
 * Add a change listener to the store, and invoke our saveState function defined above.
 
store.subscribe(() => {
    saveState(store.getState());
});
*/

export default store;
