import * as actionTypes from "../consts/galleryConsts";

export const setReduxGalleryState = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.LOGIN_GALLERY,
        payload: gallery
    });
};

export const delFav = (img: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.DEL_FAV,
        payload: img
    });
};

export const addFav = (img: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.ADD_FAV,
        payload: img
    });
};

export const updateGalleryClient = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.UPDATE_GAL,
        payload: gallery
    });
};

export const setReduxSmallGalleryState = (gallery: any) => (dispatch: (arg0: { type: any; payload: any; }) => void) => {
    dispatch({
        type: actionTypes.LOGIN_GALLERY_SHORT,
        payload: gallery
    });
};

export const logoutGallery = () => (dispatch: (arg0: { type: any; }) => void) => {
    dispatch({ type: actionTypes.LOGOUT_GALLERY });
};