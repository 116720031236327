import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import i18n from "../../i18n/config";
import './ShareGalleryModal.scss';
import api from "../../api";

function ShareGalleryModal(props: { isOpen: boolean, handleClose: any, toastManager?: any, galleryID: string | undefined }) {
    const [show, setShow] = useState(Boolean);
    const [shareData, setShareData] = useState<any>({ email: "", message: "", clientName: "" });

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    /**
 * Handle the required fields's validity status
 */
    const [validEmail, setValidEmail] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState("shareGallery.needEmail");


    /**
     * Send the share email
     */
    function share(event: any) {
        if (!shareData.email) {
            event.preventDefault();
            event.stopPropagation();
            setValidEmail(!!shareData.email);
            setEmailErrorMessage("settings.account.needEmail");
        } else if (!shareData?.email.match(/^\S+@\S+\.\S+$/)) {
            event.preventDefault();
            event.stopPropagation();
            setValidEmail(false);
            setEmailErrorMessage("settings.account.needValidEmail");
            return;
        } else {
            setValidEmail(true);
            api.post("api/user/sendMail", { receiver: { email: shareData.email, name: shareData.clientName, message: shareData.message }, galleryID: props?.galleryID, lang: i18n.language }).then((res: any) => {
                props?.toastManager("shareGallery", false);
                props?.handleClose();
            }).catch(e => {
                props?.toastManager("shareGallery.error", true);
                props?.handleClose();
            });
        }
    }

    function handleChange(data: any, type: any) {
        const newShare = shareData;
        newShare[type] = data;

        setShareData(newShare);
    }


    return (
        <>
            <Modal show={show} onHide={props?.handleClose} className="top-alert">
                <Modal.Header>{t("shareGallery.modalTitle")}</Modal.Header>
                <Form noValidate>
                    <Modal.Body>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputTitle">{t("shareGallery.clientEmail")} <span className="required">({t("general.required")})</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="inputEmail"
                                required
                                onChange={(e) => handleChange(e.target.value, "email")}
                                aria-describedby="emailHelpBlock"
                                placeholder={t("shareGallery.enterEmail")}
                                isInvalid={!validEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t(emailErrorMessage)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputClientName">{t("shareGallery.clientName")}</Form.Label>
                            <Form.Control
                                type="text"
                                id="inputClientName"
                                aria-describedby="clientNameHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "clientName")}
                                placeholder={t("shareGallery.enterClientName")}
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="inputMessage">{t("general.message")}</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="inputMessage"
                                aria-describedby="messageHelpBlock"
                                onChange={(e) => handleChange(e.target.value, "message")}
                                placeholder={t("shareGallery.enterMessage")}
                                maxLength={300}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={props?.handleClose}>
                            {t("general.cancel")}
                        </Button>
                        <Button variant="primary" onClick={(e) => { share(e); }}>
                            {t("general.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ShareGalleryModal;